import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import PagePreloader from './elements/PagePreloader';
import makeRequest from "@shared/api/makeRequest";
import ContentSwitcher from '@shared/ui/ContentSwitcher';
import MerchantsStats from './elements/MerchantsStats';
import { useSearchParams } from 'react-router-dom';


const ContentItems = [
    // { key: "all", label: "Общая", children: <AllStats /> },
    // { key: "traders", label: "Трейдеры", children: <TradersStats /> },
    { key: "merchants", label: "Мерчанты", children: <MerchantsStats />},
]

const Statistics = () => {
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const section = searchParams.get("section");
    const navigate = useNavigate();

    useEffect(fetchBalance, []);

    return (
        <PageWrapper ContentPreloader={PagePreloader} loading={loading} title="Статистика">
            <ContentSwitcher
                items={ContentItems}
                activeKey={section || ContentItems[0].key}
                onChange={(key) => {setSearchParams({section: key})}}
            />
        </PageWrapper>
    );
    function fetchBalance() {
        (async () => {
            try {
                setLoading(true);
                const {overall_statistics} = await makeRequest(`/api/v1/wallets/balance/shops`, {
                    method: "GET"
                });
                
                setBalance(overall_statistics.total_balance);
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false);
            }
        })();
    }
};

export default Statistics;
