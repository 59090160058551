import { useForm, Controller } from "react-hook-form";
// src/components/sections/Section2.js
import React from 'react';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import SimpleInput from '@shared/ui/Simpleinput';
import VBox from '@shared/ui/layouts/Vbox';
import Text from "@shared/ui/Text";
import HBox from '@shared/ui/layouts/Hbox';
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import ErrorLabel from "@shared/ui/ErrorLabel";
import setShopComissionsApi from '@shared/api/services/admin/shops/setShopComissionsApi';
import { toast } from "react-toastify";
import {TwoColumns} from "@shared/ui/containers/ListContainers";


const Commisions = ({shop}) => {    
    const { handleSubmit, control, formState: { errors } } = useForm();

    return (
        <form onSubmit={handleSubmit(setShopComissions)}>
            <VBox width='100%' gap="10px">
                <ErrorLabel value={errors.changes_required?.message}>
                    <VBox gap="10px">
                        <TwoColumns>
                            <Controller
                                name="payin"
                                control={control}
                                defaultValue={shop.rate_payin || 0}
                                rules={{ required: "Обязательное поле", min: { value: 1, message: "Минимум 1 процент" }, max: { value: 100, message: "Максимум 100 процентов" } }}
                                render={({ field }) => (
                                    <SimpleInput
                                        {...field}
                                        label="Payin (%)"
                                        defaultValue={shop.rate_payin || 0}
                                        error={errors.payin?.message}
                                        number 
                                        min={0} 
                                        max={100}
                                        placeholder='4'
                                    />
                                )}
                            />
                            <Controller
                                name="payout"
                                control={control}
                                defaultValue={shop.rate_payout || 0}
                                rules={{ required: "Обязательное поле", minLength: { value: 1, message: "Минимум 1 процент" }, max: { value: 100, message: "Максимум 100 процентов" }}}
                                render={({ field }) => (
                                    <SimpleInput
                                        {...field}
                                        label="Payout (%)"
                                        error={errors.payout?.message}
                                        defaultValue={shop.rate_payout || 0}
                                        number 
                                        min={0} 
                                        max={100} 
                                        placeholder='8'
                                    />
                                )}
                            />
                            <SimpleButton type="submit">Сохранить</SimpleButton>
                        </TwoColumns>
                    </VBox>
                </ErrorLabel>
                
                
            </VBox>
        </form>
    )

    async function setShopComissions({payin, payout}) {
        if(payin === shop.payin && payout === shop.payout) {
            return toast.error("Измените значения")
        }

        await handleAsyncNotification(async () => {
            await setShopComissionsApi({shop_id: shop.id, payin, payout});
        }, {
            successText: "Настройки сохранены",
            errorText: "Ошибка сохранения",
            loadingText: "Сохранение настроек"
        });
    }
}

export default Commisions;