// src/components/sections/Section2.js
import React, { useState, useEffect, act } from 'react';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import SimpleInput from '@shared/ui/Simpleinput';
import VBox from '@shared/ui/layouts/Vbox';
import Text from "@shared/ui/Text";
import HBox from '@shared/ui/layouts/Hbox';
import getAvailableRatesApi from "@shared/api/services/getAvailableRatesApi";
import setShopSettingsApi from '@shared/api/services/shops/setShopSettingsApi';
import {TwoColumns} from "@shared/ui/containers/ListContainers";
import Select from "@shared/ui/Select";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";

const Configurations = ({ shop}) => {
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rate_id_payin, setPayin] = useState();
    const [rate_id_payout, setPayout] = useState();
    const [bank_timeout, setBankTimeout] = useState(shop.bank_timeout);
    const [payment_timeout, setPaymentTimeout] = useState(shop.payment_timeout);

    useEffect(fetchRates, []);

    return <TwoColumns>
        <Select loading={loading} label="Парсинг курсов PayIn" options={rates} onSelect={setPayin} defaultValue={shop.rates_id}></Select>
        <Select loading={loading} label="Парсинг курсов PayOut" options={rates} onSelect={setPayout} defaultValue={shop.rates_id} ></Select>
        <SimpleInput number label="Таймаут банка (минут)" value={bank_timeout} onChange={(e) => {setBankTimeout(e.target.value)}} placeholder='15 мин.' />
        <SimpleInput number label="Таймаут оплаты (минут)" value={payment_timeout} onChange={(e) => {setPaymentTimeout(e.target.value)}} placeholder='15 мин.' />
        <SimpleButton onClick={setShopSettings}>Сохранить</SimpleButton>
    </TwoColumns>

    function fetchRates() {
        (async () => {
            setLoading(true);
            try {
                const rates = await getAvailableRatesApi(shop.id);

                setRates(rates.map(({id, label, price}) => ({value: id, label: `${label} ${price}`})));
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    async function setShopSettings() {
        if(!rate_id_payin || !rate_id_payout || !bank_timeout || !payment_timeout) return;

        await handleAsyncNotification(async () => {
            await setShopSettingsApi({rate_id_payin, rate_id_payout, bank_timeout, payment_timeout, shop_id: shop.id});
        }, {
            successText: "Настройки сохранены",
            loadingText: "Сохранение настроек",
            errorText: "Ошибка сохранения"
        });
    }
}

export default Configurations;