import ElementCard from "@shared/ui/ElementCard";
import {FiveColumns} from "@shared/ui/containers/ListContainers";
import Text from "@shared/ui/Text";
import FileLink from "@shared/ui/FileLink";
import GlassCard from "@shared/ui/GlassCard";


const ReceiptsBlock = ({receipts}) => {
    if(!receipts?.length) return;

    return <GlassCard>
        <Text weight="bold">Приложенные чеки</Text>
        <FiveColumns>
            {receipts.map(({filename, file_url, created_at}) => <FileLink type={filename.split(".")[1]} createdAt={created_at} name={filename} src={file_url} />)}  
        </FiveColumns>
    </GlassCard>
}

export default ReceiptsBlock;