import { useEffect, useState } from "react";
import Link from "@shared/ui/Link";
import getUserInfoApi from "@shared/api/services/admin/getUserInfoApi";


const TraderLink = ({trader_id}) => {
    const [traderData, setTraderData] = useState({});

    useEffect(fetchTraderData, [trader_id]);

    return <Link path={`/traders/${trader_id}`}>
        {traderData?.username}
    </Link>

    function fetchTraderData() {
        (async () => {
            if(!trader_id) return;

            try {
                const traderData = await getUserInfoApi(trader_id);

                setTraderData(traderData);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

export default TraderLink;