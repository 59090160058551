import React, { useState, useEffect } from "react";
import Text from "./Text";

const generateRandomDurations = (totalDuration, parts) => {
  const points = Array.from({ length: parts - 1 }, () => Math.random() * totalDuration);
  points.sort((a, b) => a - b);

  const intervals = [points[0]];
  for (let i = 1; i < points.length; i++) {
    intervals.push(points[i] - points[i - 1]);
  }
  intervals.push(totalDuration - points[points.length - 1]);

  return intervals;
};

const RandomMessageSwitcher = ({ duration, messages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [durations, setDurations] = useState([]);

  useEffect(() => {
    if (messages.length === 0) return;
    setDurations(generateRandomDurations(duration, messages.length));
  }, [duration, messages]);

  useEffect(() => {
    if (durations.length === 0) return;

    let timeout;
    let index = 0;

    const switchMessage = () => {
      if (index < messages.length - 1) {
        timeout = setTimeout(() => {
          setCurrentIndex((prevIndex) => prevIndex + 1);
          switchMessage();
        }, durations[index] * 1000);
        index++;
      }
    };

    switchMessage();
    return () => clearTimeout(timeout);
  }, [durations]);

  return <Text fontSize="24px">{messages[currentIndex]} ...</Text>;
};

export default RandomMessageSwitcher;
