import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useNavigate, useParams } from "react-router-dom";
import getUserInfoApi from "@shared/api/services/admin/getUserInfoApi";
import { useEffect, useState } from "react";
import ElementCard from "@shared/ui/ElementCard";
import GlassCard from "@shared/ui/GlassCard";
import Text from "@shared/ui/Text";
import Info from "@shared/ui/Info";
import InfoModel from "./elements/InfoModel";
import Vbox from "@shared/ui/layouts/Vbox";
import useAddNavigate from "@shared/hooks/useAddNavigate";
import styled from "styled-components";
import {TwoColumns} from "@shared/ui/containers/ListContainers";
import InsuranceDeposit from "./elements/InsuranceDeposit";
import getHubsListByTraderIdApi from "@shared/api/services/admin/getHubsListByTraderIdApi";
import getPHubsApi from "@shared/api/services/admin/phubs/getPHubsApi";


const HubCard = styled(ElementCard)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    color: ${props => props.theme.color};

    &:hover {
        opacity: 0.5;
    }
`

const Trader = () => {
    const {trader_id} = useParams();   
    const [traderData, setTraderData] = useState({});
    const [loading, setLoading] = useState(true); 
    const [phubsAmount, setPhubsAmount] = useState(0);
    const [rhubsAmount, setRhubsAmount] = useState(0);
    const navigate = useNavigate();
    const addNavigate = useAddNavigate();

    useEffect(fetchTraderData, []);
    useEffect(fetchPhubsAmount, []);
    useEffect(fetchRhubsAmount, []);

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/traders")}}>Трейдеры</a> }
    ];

    const title = `Трейдер "${traderData.username}"`

    return <PageWrapper breadcrumbs={breadcrumbs} loading={loading} title={title}>
        <GlassCard>
            <Vbox gap="10px">
                <Text weight="bold">Информация</Text>
                <ElementCard>
                    <Text weight="bold">Основные данные</Text>
                    <Info title="ID" value={traderData.id} />
                    <Info title="Имя" value={traderData.username} />
                    <Info title="Почта" value={traderData.email} />
                    <Info title="Телеграм" value={traderData.telegram} />
                </ElementCard>
            </Vbox>
        </GlassCard>
        <GlassCard>
            <Text weight="bold">Настройки</Text>
            <TwoColumns>
                <ElementCard>
                    <Text weight="bold">Страховой депозит</Text>
                    <InsuranceDeposit trader_id={trader_id} />
                </ElementCard>
                <TwoColumns>
                    <HubCard onClick={() => {addNavigate("/phubs")}}>
                        <Text size="medium">Payout-Hubs</Text>
                        <Text>{phubsAmount} шт.</Text>
                    </HubCard>
                    <HubCard onClick={() => {addNavigate("/hubs")}}>
                        <Text size="medium">Payin-Hubs</Text>
                        <Text>{rhubsAmount} шт.</Text>
                    </HubCard>
                </TwoColumns>                      
            </TwoColumns>
        </GlassCard>
    </PageWrapper>

    function fetchTraderData() {
        (async () => {
            try {
                setLoading(true);

                const data = await getUserInfoApi(trader_id);

                setTraderData(data);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    function fetchPhubsAmount() {
        (async () => {
            try {
                const {total} = await getPHubsApi({user_id: trader_id, page: 1});

                setPhubsAmount(total);
            } catch(e) {
                console.log(e);
            }
        })();
    }

    function fetchRhubsAmount() {
        (async () => {
            try {
                const {total} = await getHubsListByTraderIdApi({trader_id, page: 1});

                setRhubsAmount(total);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

export default Trader;