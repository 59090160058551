import { Result as AntdResult } from "antd";
import styled from "styled-components";


const Result = styled(AntdResult)`
  font-family: "Manrope", sans-serif;
  margin: 12px 0; // Уменьшаем внешние отступы
  .ant-result-title {
    font-family: "Manrope", sans-serif;
  }
  .ant-result-subtitle {
    font-family: "Manrope", sans-serif;
  }
`;

export default Result;