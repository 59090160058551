import React from "react";
import styled from "styled-components";

// Фильтрация нестандартных пропсов
const StyledDiv = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        !["gap", "height", "userSelect", "width"].includes(prop),
})`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.gap || "none"};
    transition: width 0.5s, min-width 0.5s;
    height: ${(props) => props.height || "auto"};
    user-select: ${(props) => props.userSelect || "auto"};
    width: ${(props) => props.width || "100%"};
`;

const VBox = ({
    children,
    userSelect,
    flex,
    boxShadow,
    padding,
    gap,
    width,
    backGround,
    customJust,
    borderRadius,
    borderBottom,
    fontSize,
    ...rest
}) => {
    return (
        <StyledDiv
            style={{
                flex: flex,
                userSelect,
                boxShadow: boxShadow,
                padding: padding,
                fontSize,
                gap: gap,
                width: width,
                background: backGround,
                justifyContent: customJust,
                borderBottom: borderBottom,
            }}
            {...rest}
        >
            {children}
        </StyledDiv>
    );
};

export default VBox;
