import styled from "styled-components";


export default styled.div`
  backdrop-filter: blur(10px); /* Эффект размытости, создающий стеклянный вид */
  border-radius: 10px; /* Закругленные углы */
  background: ${(props) => props.theme.secondary};
  flex-direction: column;
  display: flex;
  width: ${props => props.width || "100%"};
  border: solid 1px ${(props) => props.theme.border};
  // box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Тень для глубины */
  padding: 10px; /* Внутренние отступы для контента */
  position: relative; /* Для позиционирования бликов */
  gap: 20px;
  padding: 20px;
  height: ${props => props.height || "100%"};

  @media(max-width: ${props => props.theme.mediaSizes.small}) {
    padding: 15px;
  }
`;