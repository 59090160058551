
export const fiatCurrencies = [
    {value: "RUB", label: "RUB"}
]


export const digitalCurrencies = [
    {value: "USDT", label: "USDT"},
]

export const exchanges = [
    {value: 'Bybit', label: 'Bybit'},
    {value: 'Garantex', label: 'Garantex'}
]