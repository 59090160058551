import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import formatNumber from '@shared/helpers/formatNumber';
import Text from "@shared/ui/Text";
import Graph from './Graph';
import getHeaderRatesApi from '@shared/api/services/header/getHeaderRatesApi';
import { useEffect, useState } from 'react';
import ElementCard from "@shared/ui/ElementCard";

const success_data = [
    { name: 'Day 1', value: 1000 },
    { name: 'Day 2', value: 1300 },
    { name: 'Day 3', value: 4000 },
    { name: 'Day 4', value: 1200 },
    { name: 'Day 5', value: 1400 },
    { name: 'Day 6', value: 2000 },
    { name: 'Day 7', value: 1700 },
    { name: 'Day 8', value: 7000 },
];

const lossess_data = [
    { name: 'Day 1', value: 7000 },
    { name: 'Day 2', value: 4000 },
    { name: 'Day 3', value: 4000 },
    { name: 'Day 4', value: 1200 },
    { name: 'Day 5', value: 1400 },
    { name: 'Day 6', value: 2000 },
    { name: 'Day 7', value: 1700 },
    { name: 'Day 8', value: 1000 },
];

function InfoCard({title, data = success_data, currency, value}) { 
    const [rate, setRate] = useState();

    useEffect(fetchRate, []);

    return <ElementCard>
        <VBox gap="10px">
            <Text>{title}</Text>
            <HBox alignItems='center' customJust='none' gap="10px">
                <Text weight="bold" align="start" size="medium">{formatNumber(value)} {currency}</Text>
                
                <Text whitespace="nowrap">≈ {formatNumber(value * rate)} ₽</Text>
            </HBox>
            <Graph data={data} />
        </VBox>
    </ElementCard>

    function fetchRate() {
        (async () => {
            try {
                const {rates} = await getHeaderRatesApi();

                setRate(rates[0].price);
            } catch(e) {console.log(e)}
        })();
    }
}


export default InfoCard;