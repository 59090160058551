import Popup from "@shared/ui/Popup";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import TronQRCode from '@shared/ui/widgets/TronQrWidget';
import Icon from "@shared/ui/icons/Icon";
import { QrcodeOutlined } from "@ant-design/icons";


const ApkLink = () => {
    return <Popup content={
        <Vbox gap="10px">
            <Text><a href="https://drive.google.com/file/d/1u5BHwWOb8uNF1LzOXjqvKQqgjkGY8g3T/view?usp=sharing">Скачать автоматику</a></Text>
            <Hbox customJust="center">
            <TronQRCode address="https://drive.google.com/file/d/1u5BHwWOb8uNF1LzOXjqvKQqgjkGY8g3T/view?usp=sharing"  /> 
            </Hbox>
        </Vbox>
        }>
        <Icon icon={QrcodeOutlined} />
    </Popup>
}

export default ApkLink;