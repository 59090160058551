import { Controller } from "react-hook-form";
import SimpleInput from "@shared/ui/Simpleinput";


const ControllerInput = ({control, defaultValues, label, name, errors}) => {
    return <Controller
        name={name}
        control={control}
        defaultValue={defaultValues[name] || ""}
        rules={{ required: "Обязательное поле" }}
        render={({ field }) => (
            <SimpleInput
                {...field}
                defaultValue={defaultValues[name] || 0}
                label={label}
                error={errors[name]?.message}
            />
        )}
    />
}

export default ControllerInput;