import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { SendOutlined } from "@ant-design/icons";
import Icon from "@shared/ui/icons/Icon";
import formatReadableTime from "@shared/helpers/formatReadableTime";
import { useForm } from "react-hook-form";
import getMessagesApi from "@shared/api/services/chat/getMessagesApi";
import { useAuth } from "@shared/AuthContext";
import { useWebsocket } from "@shared/hooks/useWebsocket";
import { Header, InputContainer, MainContainer, MessagesContainer, StyledInput } from "./elements/styles";
import Message from "./elements/Message";
import DateHeader from "./elements/DateHeader";


const Chat = ({ room_id, username }) => {
  const limit = 10;
  const { user: { id: my_id } } = useAuth();
  const websocket = useWebsocket();
  const [messages, setMessages] = useState([]);
  const [offset, setOffset] = useState(0);
  const messagesContainerRef = useRef(null);
  const dummyRef = useRef(null);
  const heightRef = useRef(0);

  const { register, handleSubmit, setValue } = useForm();

  const scrollToBottom = useCallback(scrollToBottomCallback, []);
  const restoreScrollPosition = useCallback(restoreScrollPositionCallback, [scrollToBottom]);

  useEffect(fetchMessages, []);
  useEffect(initChat, [websocket, room_id, my_id]);

  useEffect(() => {
    restoreScrollPosition(heightRef.current);
  }, [messages, restoreScrollPosition]);

  const sortedMessages = useMemo(() => {
    const dates = getDates();
    const headers = getHeaders(dates);
    const result = sortByDate([...headers, ...messages]);

    return result;

    function getDates() {
      const dates = {};

      messages.forEach((message) => {
        dates[formatDate(message.created_at)] = null;
      });

      return Object.keys(dates);

      function formatDate(dateString) { // Убираем часы и минуты
        const date = new Date(dateString);
        date.setUTCHours(0, 0, 0, 0);
        
        return date.toISOString();
      }
    }

    function getHeaders(dates) { // Принимаем даты и возвращаем заголовки
      return dates.map(date => ({type: "date", created_at: date, message: getReadableDate(date)}));
    }    

    function sortByDate(items) {
      return [...items].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }

    function getReadableDate(dateString) {
      return new Date(dateString).toLocaleDateString("ru-RU", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  }, [messages]);

  return (
    <MainContainer>
      <Header>{username}</Header>
      <MessagesContainer ref={messagesContainerRef} onScroll={handleScroll}>
        {sortedMessages
          .map(({ id, type, created_at, message }) => (
            type === "date" ? <DateHeader>{message}</DateHeader> :
            <Message
              key={id}
              type={type}
              time={formatReadableTime(created_at)}

              message={message}
            />
          ))}
        <div ref={dummyRef} />
      </MessagesContainer>
      <InputContainer>
        <form onSubmit={handleSubmit(({ value }) => sendMessage(value))} style={{ display: "flex", width: "100%" }}>
          <StyledInput
            {...register("value", { required: true })}
            placeholder="Введите сообщение"
            autoComplete="off"
          />
          <button type="submit" style={{ border: "none", background: "none", padding: 0 }}>
            <Icon icon={SendOutlined} />
          </button>
        </form>
      </InputContainer>
    </MainContainer>
  );

  function sendMessage(message) {
    if (!message.trim()) return;
    websocket.send(JSON.stringify({ event: "message", room_id, message }));
    setValue("value", "");
  };

  function fetchMessages() {
    (async () => {
        try {
        const { messages: newMessages } = await getMessagesApi({
            room_id,
            limit,
            offset,
        });

        setMessages((prev) => {
            const uniqueMessages = new Map(prev.map((msg) => [msg.id, msg]));
            newMessages.forEach(({ id, message, created_at, user_id }) => {
            uniqueMessages.set(id, {
                id,
                type: user_id === my_id ? "right" : "left",
                created_at,
                message,
            });
            });
            return Array.from(uniqueMessages.values()).sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
            );
        });

        if (newMessages.length >= limit) {
            setOffset((prev) => prev + limit);
        }
        } catch (error) {
        console.error("Ошибка загрузки сообщений:", error);
        }
    })();
  }

  function handleScroll() {
    const container = messagesContainerRef.current;

    if (container.scrollTop === 0) {
      const prevHeight = container.scrollHeight;
      heightRef.current = prevHeight;
      fetchMessages();
    }
  }

  function restoreScrollPositionCallback(prevHeight) {
    const container = messagesContainerRef.current;

    if (container.scrollTop === 0) {
      container.scrollTop = container.scrollHeight - prevHeight;
    } else {
      scrollToBottom();
    }
  }

  function scrollToBottomCallback() {
    if (dummyRef.current) {
        dummyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  function initChat() {
    if (websocket) {
        websocket.onopen = () => {
          websocket.send(JSON.stringify({ event: "init_room", room_id }));
        };

        websocket.onmessage = (event) => {
        try {
            const data = JSON.parse(event.data);
            
            if (data.event === "message") {
            const { id, message, created_at, user_id } = data;

            const newMessage = {
                id,
                type: user_id === my_id ? "right" : "left",
                created_at,
                message,
            };

            setMessages((prev) => {
                const uniqueMessages = new Map(prev.map((msg) => [msg.id, msg]));
                uniqueMessages.set(newMessage.id, newMessage);

                return Array.from(uniqueMessages.values()).sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
                );
            });
            }
        } catch (error) {
            console.error("Ошибка обработки WebSocket сообщения:", error);
        }
        };

        return () => {
        websocket.onmessage = null;
        websocket.onopen = null;
        };
    }
  }
};

export default Chat;
