import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import {useAuth} from "@shared/AuthContext";
import SupportCloseDisputeModal from "@features/SupportCloseDisputeModal";
import OpenDisputeModal from "@features/OpenDisputeModal";
import {useModal} from "@shared/ModalContext";
import Vbox from "@shared/ui/layouts/Vbox";
import Text from "@shared/ui/Text";
import { useMemo } from "react";
import ReceiptsUploader from "@features/ReceiptUploader";
import makeRequest from "@shared/api/makeRequest";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import ElementCard from "@shared/ui/ElementCard";


const AdminActions = ({orderData, onSuccess}) => {
    const {user} = useAuth();
    const {openModal} = useModal();
    const payinMenuItems = useMemo(getPayinMenuItems, [orderData, user]);
    const payoutMenuItems = useMemo(getPayoutMenuItems, [orderData]);
    const payment_type = orderData.payment_type;
    const menuItems = payment_type === "payout" ? payoutMenuItems : payinMenuItems;
    
    if(menuItems.length < 1) return;

    return <ElementCard>
        <Vbox gap="10px">
            <Text weight="bold">Действия</Text>
            {menuItems?.map(({label, onClick}) => <SimpleButton key={label} onClick={onClick}>{label}</SimpleButton>)}
            {/* Аплоадер отображаем только если 1) транзакция payin в диспуте, либо 2) она payout и активна */}
            {orderData.status?.name === "dispute" || (orderData.status?.name === "active" && payment_type === "payout") ? <ReceiptsUploader onSuccess={onSuccess} transaction_id={orderData.id} /> : <></>}
        </Vbox>
    </ElementCard>
    

    function getPayoutMenuItems() {
        const items = {
            dispute: {
                any: [
                    ToSuccess()
                ],
            },
            successful: {
                any: [
                    OpenDispute()
                ]
            },
            active: {
                any: [
                    OpenDispute(),
                ],
            },
            canceled: {
                any: [
                    OpenDispute(),
                ]
            },
            validation: {
                any: [
                    ToSuccess(),
                    OpenDispute()
                ]
            }
        }

        const statusItems = items[orderData.status?.name];

        if(!statusItems) return [];

        const subreasonItems = statusItems[orderData.subreason?.code] || [];
        
        return [...subreasonItems, ...statusItems['any']];

        function OpenDispute() {
            return getButton('Открыть диспут', () => {openModal(<OpenDisputeModal onSuccess={onSuccess} transaction={orderData}/>, 'Открытие диспута')}) 
        }
        
        function ToSuccess() {
            return getButton('Переместить в успех', toSuccessApi);

            async function toSuccessApi () {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/transactions/${orderData.id}/payout/successful`, {
                        method: "GET"
                    });
                    onSuccess();
                },{
                    loadingText: "Перемещение в успех",
                    errorText: "Ошибка перемещения в успех",
                    successText: "Успешно перемещено в успех"
                });
            }
        }
    }

    function getPayinMenuItems() {
        const items = {
            dispute: {
                any: [
                    CloseDispute(),
                ],
                received_not_confirmed: [
                    FictitiousCheck(),
                    IncorrectCheck(),
                    RequireVideo(),
                ],
                video_confirmation_uploaded: [
                    VideoConfirm(),
                    VideoRefute()
                ],
                disputed_by_merchant: [
                    NonStandartSituation()
                ],
                redisputed_by_merchant: [
                    NonStandartSituation()
                ]
            },
            successful: {
                any: [
                    OpenDispute()
                ]
            },
            active: {
                any: [],
            },
            canceled: {
                any: [
                    OpenDispute()
                ]
            },
            validation: {
                any: []
            }
        }

        const statusItems = items[orderData.status?.name];

        if(!statusItems) return [];

        const subreasonItems = statusItems[orderData.subreason?.code] || [];
        
        return [...subreasonItems, ...statusItems['any']];

        function OpenDispute() {
            return getButton('Открыть диспут', () => {openModal(<OpenDisputeModal onSuccess={onSuccess} transaction={orderData}/>, 'Открытие диспута')}) 
        }

        function CloseDispute() {
            return getButton('Закрыть диспут', () => {openModal(<SupportCloseDisputeModal onSuccess={onSuccess} transaction={orderData} />, 'Закрытие диспута')})
        }

        function FictitiousCheck() {
            return getButton('Фиктивный чек', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/support/cancel/fake?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess();
                }, {
                    successText: "Операция выполнена",
                    errorText: "Ошибка",
                    loadingText: "Фиктивирование"
                })
            })
        }

        function IncorrectCheck() {
            return getButton('Неправильный чек', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/support/cancel/incorrect?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess();
                }, {
                    successText: "Операция выполнена",
                    errorText: "Ошибка",
                    loadingText: "Неправильно чекирование"
                })
            })
        }

        function RequireVideo() {
            return getButton('Запросить видеодоказательство', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/support/refute?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess();
                }, {
                    successText: "Операция выполнена",
                    errorText: "Ошибка",
                    loadingText: "Видео доказательство запрашивание"
                });
            });
        }

        function VideoConfirm() {
            return getButton('Подтвердить видео', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/support/video/confirm?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess();
                }, {
                    successText: "Видео подтверждено",
                    errorText: "Ошибка",
                    loadingText: "Подтверждение видео"
                });
            });
        }

        function VideoRefute() {
            return getButton('Запросить видео', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute//support/video/refute?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess();
                }, {
                    successText: "Видео запрошено",
                    errorText: "Ошибка",
                    loadingText: "Запрашивание видео"
                });
            });
        }

        function NonStandartSituation() {
            return getButton('Нестандартная ситуация', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/support/non-standart?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess();
                },{
                    successText: "Помечено как нестандартная ситуация",
                    errorText: "Ошибка",
                    loadingText: "Сохранение как нестандартной ситуации"
                })
            })
        }

    };

    function getButton(label, onClick) {
        return {
            label,
            onClick,
        }
    }
}

export default AdminActions;