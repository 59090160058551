import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import { useEffect, useState } from "react";
import HubTypeDropDown from "./elements/HubTypeDropDown";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import editRecHubApi from "@shared/api/services/hubs/editRecHubApi";
import getHubDataApi from "@shared/api/services/hubs/getHubDataApi";
import NameInput from "./elements/NameInput";
import { useForm } from "react-hook-form";
import Loading from "@shared/ui/Loading";
import DescriptionInput from "./elements/DescriptionInput";
import { CheckOutlined } from "@ant-design/icons";


const EditRecHubModal = ({hubId, onSuccess, closeModal}) => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(true);
    const [defaultDescription, setDefaultDescription] = useState();
    const [defaultName, setDefaultName] = useState();
    const [defaultTraficMode, setDefaultTraficMode] = useState();

    useEffect(fetchHubData, []);

    if(loading) return <Loading />
    
    return <form onSubmit={handleSubmit(editRecHub)}>
        <Vbox gap="10px">
            <NameInput control={control} defaultValue={defaultName} error={errors.name?.message} />
            <DescriptionInput control={control} defaultValue={defaultDescription} error={errors.description?.message} />
            <HubTypeDropDown defaultValue={defaultTraficMode} control={control} error={errors.trafic_mode_id?.message} />
            <Hbox customJust="end"><SimpleButton main icon={<CheckOutlined />} type="submit">Сохранить</SimpleButton></Hbox>
        </Vbox>
    </form>
    

    async function editRecHub({name, description, trafic_mode_id}) {
        closeModal();
        await handleAsyncNotification(async () => { 
            await editRecHubApi(hubId, {
                name,
                description,
                trafic_mode_id,
            });
            onSuccess();
        }, {
            successText: "Хаб успешно обновлен",
            errorText: "Ошибка изменения хаба",
            loadingText: "Изменение хаба"
        });
    }

    function fetchHubData() {
        (async () => {
            try {
                setLoading(true);

                const {description, name, trafic_mode_id} = await getHubDataApi(hubId);
            
                setDefaultName(name);
                setDefaultDescription(description);
                setDefaultTraficMode(trafic_mode_id);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }            
        })();
    }
}

export default EditRecHubModal;