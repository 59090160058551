import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import styled from "styled-components";
import convertUTCToLocal from "../../helpers/convertUTCToLocal";

const Container = styled.div`
    padding: 10px;
    max-width: 300px;
    background-color: ${props => !props.viewed ? "#E0E0E0" : "none"};
    border-radius: 10px;
`


const Notification = ({children, viewed, date}) => {
    return <Container viewed={viewed}>
        <Vbox>
            <Hbox alignItems="end" gap="10px">
                <Text weight="bold" color="black" size="small">{convertUTCToLocal(date)}</Text>
            </Hbox>
            <Text align="start" color="black" size="small">
                {removeTags(children)}
            </Text>
        </Vbox>
    </Container>

    function removeTags(str) {
        return str?.replace(/<[^>]*>/g, '');  // Удаляем все, что заключено в угловые скобки
    }
}

export default Notification;