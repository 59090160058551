import { EditOutlined } from "@ant-design/icons";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Hbox from "@shared/ui/layouts/Hbox";
import { namings } from "../../../../app/constants";
import Rate from "../elements/Rate";


export function getUnlinkedsColumns(openLinkingModal, openLimitsEditModal) {
    return [
        {
            title: "Название",
            dataIndex: "name",
        },
        {
            title: "Турбо",
            dataIndex: "turbo_mode",
            render: (_, {turbo_mode}) => `${turbo_mode ? "Включен" : "Не включен"}`
        },
        {
            title: namings.max_hourly_orders,
            dataIndex: "max_hourly_orders",
        },
        {
            title: namings.max_dayly_orders,
            dataIndex: "max_daily_orders",
        },
        {
            title: namings.max_active_tx,
            dataIndex: "max_active_tx",
        },
        {
            title: namings.max_hourly_volume,
            dataIndex: "max_hourly_volume",
        },
        {
            title: namings.max_dayly_volume,
            dataIndex: "max_daily_volume",
        },
        {
            title: namings.min_transaction_amount,
            dataIndex: "min_transaction_amount",
        },
        {
            title: "Турбо",
            dataIndex: "turbo_mode",
            render: (_, {turbo_mode}) => `${turbo_mode ? "Включен" : "Не включен"}`
        },
        {
            title: namings.max_transaction_amount,
            dataIndex: "max_transaction_amount",
        },
        {
            title: "",
            dataIndex: "",
            width: "1px",
            render: (_, {id}) => <Hbox gap="10px">
                <SimpleButton icon={<EditOutlined />} onClick={() => {openLimitsEditModal(id)}}>
                </SimpleButton>
                <SimpleButton onClick={() => {openLinkingModal(id)}}>Привязать</SimpleButton>
            </Hbox>,
            fixed: "right"
        }
    ]
}

export function getLinkedsColumns(openUnlinkModal, openLimitsEditModal) {
    return [
        {
            title: "Название",
            dataIndex: "hub_name",
        },
        {
            title: "Ставка",
            dataIndex: "_",
            render: (_, {payin, payout, rate}) => rate ? rate : <Rate payin={payin} payout={payout} /> 
        },
        {
            title: "Макс. заказов в час",
            dataIndex: "max_hourly_orders",
        },
        {
            title: "Макс. заказов в сутки",
            dataIndex: "max_daily_orders",
        },
        {
            title: "Макс. объем в час",
            dataIndex: "max_hourly_volume",
        },
        {
            title: "Мин. объем транзакции",
            dataIndex: "min_transaction_amount",
        },
        {
            title: "Макс. объем транзакции",
            dataIndex: "max_transaction_amount",
        },
        {
            title: "Турбо",
            dataIndex: "turbo_mode",
            render: (_, {turbo_mode}) => `${turbo_mode ? "Включен" : "Не включен"}`
        },
        {
            title: "",
            dataIndex: "edit_limits",
            width: "1px",
            render: (_, {hub_id}) => <SimpleButton onClick={() => {openLimitsEditModal(hub_id)}}>Править лимиты</SimpleButton>
        },
        {
            title: "",
            dataIndex: "unlink",
            width: "1px",
            render: (_, {id, hub_name}) => <SimpleButton onClick={() => {openUnlinkModal(id, hub_name)}}>Отвязать</SimpleButton>
        }
    ]
}