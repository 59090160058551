import { types } from "..";
import useSearchParams from "@shared/hooks/useSearchParams";


const Rate = ({payin, payout}) => {
    const {searchParams} = useSearchParams();
    const type = searchParams.get("type") || types[0].value;

    if(type === "payin") return payin;
    
    return payout;
}

export default Rate;