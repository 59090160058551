import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled.div`
    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.border};
    }
    width: fit-content;
    border-radius: 4px;
`

const Link = ({children, path}) => {
    const navigate = useNavigate();

    return <StyledLink onClick={() => {navigate(path)}}>
        {children}
    </StyledLink>
}

export default Link;