import { useSearchParams } from "react-router-dom";
import WaitingBlock from "./elements/WaitingBlock";
import SuccessBlock from "./elements/SuccessBlock";
import ErrorBlock from "./elements/ErrorBlock";
import { useEffect, useState } from "react";
import axios from "axios";


const Redirect = () => {
    const [searchParams] = useSearchParams();
    const transaction_id = searchParams.get("transaction_id");
    const [redirectLink, setRedirectLink] = useState("");
    const [isError, setIsError] = useState(false);

    useEffect(fetchRedirectLink, [transaction_id]);

    if(isError) return  <ErrorBlock />

    if(redirectLink) return <SuccessBlock link={redirectLink} />

    return <WaitingBlock />

    function fetchRedirectLink() {
        (async () => {
            setTimeout(async () => {
                try {
                    if(!transaction_id) return;

                    const {data} = await axios.get(`https://sandbox.many-pay.com/api/v1/orders/transactions/payment-link/${transaction_id}`, { timeout: 30000 });

                    if(!data?.payment_url) return;

                    setRedirectLink(data.payment_url);
                    setIsError(false);
                    window.location.href = data.payment_url;
                } catch(e) {
                    console.log(e);
                    
                    setIsError(true);
                }
            }, 17000)
        })()
    }
}

export default Redirect;