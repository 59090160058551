import React from "react";

const TruncateText = ({ text, maxLength }) => {
  if (!text) return null; // Если текст пустой, ничего не рендерим

  const shouldTruncate = text.length > maxLength;
  const truncatedText = shouldTruncate ? text.slice(0, maxLength) + "..." : text;

  return <span title={shouldTruncate ? text : undefined}>{truncatedText}</span>;
};

export default TruncateText;
