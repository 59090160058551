export const digitalCurrencies = ['USDT'];
export const fiatCurrencies = ['RUB'];

export const initialValues = {
    formData: {
        name: '',
        websiteurl: '',
        websiteip: '',
        digitalcurrency: digitalCurrencies[0],
        paymentrules: { numberCard: {}, SBP: {}, numberInvoice: {} },
    },
    errors: {
        name: false,
        websiteurl: false,
        websiteip: false,
        digitalcurrency: false,
        fiatcurrency: false,
    },
    newRule: {
        numberCard: { ruleCardFirst6: false, fio: false },
        SBP: { fio: false, bank: false, phonelast4: false },
        numberInvoice: { fio: false },
    }
}

export const defaultFormDataInitialValues = {
    name: '',
    websiteurl: '',
    websiteip: '',
    digitalcurrency: digitalCurrencies[0],
    fiatcurrency: fiatCurrencies[0],
    paymentrules: { numberCard: {}, SBP: {}, numberInvoice: {} },
}