// src/components/sections/Section1.js
import React from 'react';
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import TwoFactorAuth from '@features/TwoFactorAuth';
import {ThreeColumns} from "@shared/ui/containers/ListContainers";
import TelegramLogin from '@features/TelegramLogin';


const Settings = () => {
    return <PageWrapper title="Настройки">
        <ThreeColumns>
            <TwoFactorAuth />
            <TelegramLogin />
        </ThreeColumns>
    </PageWrapper>
}

export default Settings;