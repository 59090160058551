import makeRequest from "@shared/api/makeRequest";

export default async function({skip, order_id, limit, transactionStatus, merchant_name, shop_name, card_number, chosen_banks, method, amount, date_from, date_to }) {
    return await makeRequest(`/api/v1/orders/transactions/payin?skip=${skip}&limit=${limit}`, {
        body: {
            card_number,
            bank: chosen_banks,
            shop_name,
            merchant_name,
            method,
            order_id,
            ...(amount ? {amount} : {}),
            date_from,
            date_to,
            category: transactionStatus,
        }
    });
}