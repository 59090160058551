import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useEffect, useState, useMemo } from "react";
import getUserInfoApi from "@shared/api/services/admin/getUserInfoApi";
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import {useModal} from "@shared/ModalContext";
import CreatePHubModal from "./elements/CreatePHubModal";
import Pagination from "@shared/ui/Pagination";
import getPhubsApi from "@shared/api/services/admin/phubs/getPHubsApi";
import switchPHubApi from "@shared/api/services/admin/phubs/switchPHubApi";
import RequestSwitcher from "@features/RequestSwitcher";
import EditPHubModal from "./elements/EditPHubModal";
import GlassCard from "@shared/ui/GlassCard";
import DeletePHubModal from "./elements/DeletePHubModal";
import { DeleteOutlined, EditOutlined, LoginOutlined, PlusOutlined } from "@ant-design/icons";
import getPaymentMethodsApi from "@shared/api/services/getPaymentMethodsApi";
import getFiatCurrenciesApi from "@shared/api/services/getFiatCurrenciesApi";
import Text from "@shared/ui/Text";
import Table from "@shared/ui/Table";
import CopyableText from "@shared/ui/CopyableText";
import useAddNavigate from "@shared/hooks/useAddNavigate";


const PHubs = () => {
    const { trader_id } = useParams();
    const [usernameLoading, setUsernameLoading] = useState(true);
    const [username, setUsername] = useState("");
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [phubs, setPHubs] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [phubsLoading, setPhubsLoading] = useState(false);
    const { openModal } = useModal();
    const navigate = useNavigate();
    const addNavigate = useAddNavigate();

    useEffect(fetchPaymentMethods, []);
    useEffect(fetchCurrencies, []);
    useEffect(fetchUsername, []);
    useEffect(fetchPHubs, [currentPage]);

    const title = `Payout-Hubs трейдера "${username}"`;
    const breadcrumbs = useMemo(getBreadcrumbs, [navigate, username]);

    const columns = useMemo(getColumns, [paymentMethods, currencies, currentPage]);

    return (
        <PageWrapper breadcrumbs={breadcrumbs} loading={usernameLoading} title={title}>
            <Hbox customJust="end">
                <SimpleButton icon={<PlusOutlined />} main onClick={openCreatePHubModal}>Создать Payout-Hub</SimpleButton>
            </Hbox>
            <GlassCard>
                <Text weight="bold">Список Payout-Hubs</Text>
                <Table loading={phubsLoading} columns={columns} dataSource={phubs} />
                <Hbox customJust="center">
                    <Pagination value={currentPage} total={totalPages} onChange={setCurrentPage} />
                </Hbox>
            </GlassCard>
        </PageWrapper>
    );

    function fetchUsername() {
        (async () => {
            setUsernameLoading(true);
            try {
                const {username} = await getUserInfoApi(trader_id);
                
                setUsername(username);
            } catch (e) {
                console.log(e);
            } finally {
                setUsernameLoading(false);
            }
        })();
    }

    function fetchPaymentMethods() {
        (async () => {
            try {
                const paymentMethods = await getPaymentMethodsApi();
                setPaymentMethods(paymentMethods);
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function fetchCurrencies() {
        (async () => {
            try {
                const currencies = await getFiatCurrenciesApi();
                setCurrencies(currencies);
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function getColumns() {
        return [
            { title: "ID", dataIndex: "id", render: (value) => <CopyableText text={value} />, width: "1px", fixed: "left" },
            { title: "Название", dataIndex: "name" },
            { title: "Метод оплаты", dataIndex: "payment_method_id", render: (value) => paymentMethods.find(({ id }) => id === value)?.name },
            { title: "Валюта", dataIndex: "currency_id", render: (value) => currencies.find(({ id }) => id === value)?.currency },
            { title: "Мин. сумма транзакции", dataIndex: "min_transaction_amount" },
            { title: "Макс. сумма транзакции", dataIndex: "max_transaction_amount" },
            { title: "Макс. кол-во транзакций", dataIndex: "max_active_tx" },
            { title: "Статус (Трейдер)", dataIndex: "user_enabled", render: (value) => value ? "Включен" : "Не включен" },
            { title: "Статус (Админ)", dataIndex: "admin_enabled", render: (value, { id }) => <RequestSwitcher defaultValue={value} request={async () => {await switchPHubApi(id)}} /> },
            {
                title: "", width: "1px", dataIndex: "action", render: (_, { id, name }) => (
                    <Hbox gap="10px">
                        <SimpleButton onClick={() => {addNavigate(`/${id}`)}} icon={<LoginOutlined />}>К заявкам</SimpleButton>
                        <SimpleButton onClick={() => openEditPHubModal(id)} icon={<EditOutlined />} />
                        <SimpleButton onClick={() => openDeletePHubModal(id, name)} icon={<DeleteOutlined />} />
                    </Hbox>
                ),
            },
        ];
    }

    function openCreatePHubModal() {
        openModal(<CreatePHubModal onSuccess={fetchPHubs} trader_id={trader_id} />, "Создание Payout-Hub");
    }

    function openEditPHubModal(phub_id) {
        openModal(<EditPHubModal phub_id={phub_id} onSuccess={fetchPHubs} />, "Изменение Payout-Hub");
    }

    function openDeletePHubModal(phub_id, phub_name) {
        openModal(<DeletePHubModal phub_id={phub_id} phub_name={phub_name} onSuccess={fetchPHubs} />, "Удаление Payout-Hub");
    }

    function fetchPHubs() {
        (async () => {
            setPhubsLoading(true);
            try {
                const { data, total_pages } = await getPhubsApi({ user_id: trader_id, page: currentPage });

                setTotalPages(total_pages);
                setPHubs(data);
            } catch (e) {
                console.log(e);
            } finally {
                setPhubsLoading(false);
            }
        })();
    }

    function getBreadcrumbs() {
        return [
            { title: <a onClick={() => {navigate("/traders")}}>Трейдеры</a> },
            { title: <a onClick={() => {navigate(`/traders/${trader_id}`)}}>Трейдер "{username}"</a> },
        ]
    }
};


export default PHubs;