import Result from "../components/Result";
import VBox from "../components/Vbox";


const ErrorBlock = () => {
  return <VBox height="100vh" customJust="center" alignItems="center">
    <Result 
        status="error"
        title="Ошибка создания ссылки"
        subTitle="Возникла неизвестная ошибка создания ссылки. Попробуйте повторить запрос позже"
    />
  </VBox>
};

export default ErrorBlock;
