import { memo, useEffect, useState } from "react";
import getBanksApi from "@shared/api/services/getBanksApi";
import SimpleInput from "@shared/ui/Simpleinput";
import Popup from "@shared/ui/Popup";
import styled from "styled-components";
import Vbox from "@shared/ui/layouts/Vbox";
import Select from "@shared/ui/Select";
import RangePicker from "@shared/ui/RangePicker";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import dayjs from "dayjs";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import useSearchParams from "@shared/hooks/useSearchParams";


const FiltersContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`


const Filters = memo(() => {
    const {searchParams, updateSearchParams} = useSearchParams();
    const chosenBanks = searchParams.get("banks")?.split("_").filter(el => el.length > 0);
    const merchantName = searchParams.get("merchant_name");
    const shopName = searchParams.get("shop_name");
    const cardNumber = searchParams.get("card_number");
    const method = searchParams.get("method");
    const orderId = searchParams.get("order_id");
    const amount = searchParams.get("amount");
    const [banks, setBanks] = useState([]);
    const [open, setOpen] = useState(false);
    // Динамически вычисляем даты: неделя назад и текущий день
    const date_from = searchParams.get("date_from") ? dayjs(searchParams.get("date_from")) : undefined;
    const date_to = searchParams.get("date_to") ? dayjs(searchParams.get("date_to")) : undefined;

    useEffect(fetchBanks, []);

    return <Popup open={open} content={
        <Vbox gap="10px">
            <div><RangePicker startDate={date_from} endDate={date_to} label="Период" onChange={(startDate, endDate) => {
                updateSearchParams({date_from: startDate?.toISOString() || "", date_to: endDate?.toISOString() || ""})
            }} /></div>
            <FiltersContainer>
                <SimpleInput label="Номер карты" value={cardNumber} onChange={(e) => {updateSearchParams({card_number: e.target.value})}} />
                <SimpleInput label="Метод оплаты" value={method} onChange={(e) => {updateSearchParams({method: e.target.value})}} />
                <SimpleInput label="Название мерчанта" value={merchantName} onChange={(e) => {updateSearchParams({merchant_name: e.target.value})}} />
                <SimpleInput label="Название магазина" value={shopName} onChange={(e) => {updateSearchParams({shop_name: e.target.value})}} />
                <SimpleInput label="ID ордера" value={orderId} onChange={(e) => {updateSearchParams({order_id: e.target.value})}} />
                <SimpleInput label="Сумма" value={amount} onChange={(e) => { updateSearchParams({amount: e.target.value})}} />
            </FiltersContainer>
            <Select value={chosenBanks} mode="multiple" label="Выбор банков" options={banks} onChange={(value) => updateSearchParams({banks: value.join("_")})} />
            <SimpleButton onClick={() => {setOpen(false)}} main>Применить</SimpleButton>
        </Vbox>
    }>
        <SimpleButton onClick={() => {setOpen(prev => !prev)}} icon={<MenuUnfoldOutlined />}>Открыть фильтры</SimpleButton>
    </Popup>

    function fetchBanks() {
        (async () => {
            try {
                const banks = await getBanksApi();

                setBanks(banks.map(({name, code}) => ({value: code, label: name})));
            } catch(e) {
                console.log(e);
            }
        })();
    }
})

export default Filters;