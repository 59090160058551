import {useState, useEffect} from 'react'
import SimpleInput from '@shared/ui/Simpleinput'
import VBox from '@shared/ui/layouts/Vbox';
import HBox from '@shared/ui/layouts/Hbox';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import makeRequest from '@shared/api/makeRequest';
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";


const InsuranceDeposit = ({ trader_id }) => {
    const [insurance, setInsurance] = useState(0);

    useEffect(fetchInsurance, []);
    
    return (
        <VBox gap='24px'>
            <SimpleInput value={insurance} label="Обязательный страховой депозит" onChange={(e) => setInsurance(e.target.value)}></SimpleInput>
            <HBox customJust="end">
                <SimpleButton main onClick={changeInsurance}>Сохранить</SimpleButton>
            </HBox>
        </VBox>
    )

    function fetchInsurance() {
        (async () => {
            try {
                const insurance = await makeRequest(`/api/v1/core/traders/current_insurance?trader_id=${trader_id}`, {
                    method: 'GET'
                });

                setInsurance(insurance);
            } catch(e) {
                console.log(e);
            }
        })();
    } 

    function changeInsurance() {
        (async () => {
            try {
                await handleAsyncNotification(async () => {
                    await makeRequest('/api/v1/core/traders/change_insurance', {
                        body: {
                            trader_id,
                            amount: insurance
                        }
                    });
                    fetchInsurance();
                }, {
                    successText: "Настройки сохранены",
                    errorText: "Ошибка сохранения",
                    loadingText: "Сохранение настроек"
                });
            } catch(e) {
                console.log(e)
            }
        })();
    }
};
export default InsuranceDeposit;