import Table from "@shared/ui/Table";
import { useMemo } from "react";
import deleteHubShopAssosiationApi from "@shared/api/services/assosiations/deleteHubShopAssosiationApi";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import {useModal} from "@shared/ModalContext";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import Text from "@shared/ui/Text";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import EditHubLimitsModal from "@features/EditHubLimitsModal";
import { getLinkedsColumns } from "../lib/getColumns";
import Pagination from "@shared/ui/Pagination";
import useSearchParams from "@shared/hooks/useSearchParams";
import deleteHubPayoutShopAssosiationApi from "@shared/api/services/assosiations/deleteHubPayoutShopAssosiationApi";


const Linkeds = ({loading, totalPages, currentPage, onSuccess, setCurrentPage, items, traderName}) => {
    const {searchParams} = useSearchParams();
    const {openModal} = useModal();
    const type = searchParams.get("type") || "payin";

    const columns = useMemo(() => getLinkedsColumns(openUnlinkModal, openLimitsEditModal), [openUnlinkModal, openLimitsEditModal]);

    return <Vbox gap="10px">
        <Text weight="bold">Список привязанных хабов</Text>
        <Table columns={columns} loading={loading} dataSource={items} />
        <Pagination total={totalPages} onChange={setCurrentPage} value={currentPage} />
    </Vbox>

    function openUnlinkModal(assosiation_id, hubName) {
        openModal(<UnlinkModal type={type} assosiation_id={assosiation_id} hubName={hubName} traderName={traderName} onSuccess={onSuccess}/>, "Удаление связи");
    }

    function openLimitsEditModal(hub_id) {
        openModal(<EditHubLimitsModal onSuccess={onSuccess} hub_id={hub_id} />, "Правка лимитов")
    }
}

function UnlinkModal({type, hubName, traderName, onSuccess, assosiation_id, closeModal}) {
    return <Vbox gap="10px">
        <Text>Подтвердите удаление связи между:</Text>
        <Text>Трейдером "{traderName}"</Text>
        <Text>Магазином "{hubName}"</Text>
        <Hbox>
            <SimpleButton onClick={deleteHubShopAssosiation}>Удалить</SimpleButton>
        </Hbox>
    </Vbox>

    async function deleteHubShopAssosiation() {
        closeModal();
        try {
            await handleAsyncNotification(async () => {
                type === "payin" ?
                    await deleteHubShopAssosiationApi(assosiation_id)
                    :
                    await deleteHubPayoutShopAssosiationApi(assosiation_id)
                onSuccess();
            }, {
                successText: "Связь удалена",
                errorText: "Ошибка удаления связи",
                loadingText: "Удаление связи"
            });
        } catch(e) {
            console.log(e);
        }
    }
}

export default Linkeds;