import { useEffect, useState } from "react";
import Link from "@shared/ui/Link";
import getShopApi from "@shared/api/services/shops/getShopApi";



const ShopLink = ({shop_id}) => {
    const [shopData, setShopData] = useState({});

    useEffect(fetchShopData, [shop_id]);

    return <Link path={`/shops/${shop_id}`}>
        {shopData?.name}
    </Link>

    function fetchShopData() {
        (async () => {
            if(!shop_id) return;

            try {
                const data = await getShopApi(shop_id);

                setShopData(data);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

export default ShopLink;