import styled from "styled-components";
import VBox from "../components/Vbox";
import { LoadingOutlined } from '@ant-design/icons';
import RandomMessageSwitcher from "../components/RandomMessageSwitcher";
import Hbox from "../components/Hbox";
import { Spin } from "antd";


const Container = styled.div`
    overflow: hidden;
`

const Text = styled.span`
    font-family: Manrope;
    font-size: ${props => props.fontSize};
`

const messages = ["Установка безопасного соединения", "Создание заявки", "Сохранение в базе данных", "Проверка доступов", "Синхронизация внутренней структуры", "Проверка параметров безопасности", "Настройка доступов", "Передача платежных данных", "Проверка результата"];

const WaitingBlock = () => {
    return <Container>
        <VBox height="100vh" gap="20px" align="center" customJust="center">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 124, color: "#2d77f8" }} spin />} />
            <Hbox customJust="center">
                <Hbox alignItems="center" customJust="center" gap="10px" width="fit-content">
                <RandomMessageSwitcher duration={21} messages={messages} />
                </Hbox>
            </Hbox>
            <Text>Ожидайте редирект. Процесс занимает менее 1 минуты</Text>
        </VBox>
    </Container>
}

export default WaitingBlock;