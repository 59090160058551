import PageWrapper from "@shared/ui/layouts/PageWrapper";
import { useEffect, useState } from "react";
import makeRequest from "@shared/api/makeRequest";
import InfoCard from '@shared/ui/InfoCard';
import {ThreeColumns} from '@shared/ui/containers/ListContainers';
import GlassCard from "@shared/ui/GlassCard";
import Text from "@shared/ui/Text";


const data1 = [
    { name: 'Day 1', value: 5000 },
    { name: 'Day 2', value: 7500 },
    { name: 'Day 3', value: 12000 },
    { name: 'Day 4', value: 15000 },
    { name: 'Day 5', value: 20000 },
    { name: 'Day 6', value: 30000 },
    { name: 'Day 7', value: 40000 },
    { name: 'Day 8', value: 65673 },
];

const data2 = [
    { name: 'Day 1', value: 5000 },
    { name: 'Day 2', value: 5000 },
    { name: 'Day 3', value: 5000 },
    { name: 'Day 4', value: 5000 },
    { name: 'Day 5', value: 5000 },
    { name: 'Day 6', value: 5000 },
    { name: 'Day 7', value: 5000 },
    { name: 'Day 8', value: 5000 },
];

const Assets = () => {
    const [balance, setBalance] = useState(0);
    useEffect(fetchBalance, []);

    return <PageWrapper title="Активы">
        <GlassCard>
            <Text weight="bold">Основные показатели</Text>
            <ThreeColumns>
                <InfoCard data={data1} currency="USDT" title="Баланс" value={balance.total_balance}/>
                <InfoCard data={data2} currency="USDT" title="Замороженный баланс" value={balance.frozen_balance} />
                <InfoCard data={data1} currency="USDT" title="Страховой депозит" value={balance.insurance_deposit} />
            </ThreeColumns>
        </GlassCard>
    </PageWrapper>

    function fetchBalance() {
        (async () => {
            const data = await makeRequest("/api/v1/wallets/balance/", { method: "GET" });

            setBalance(data);
        })();
    }
}

export default Assets;