// src/routes/merchantRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import Loading from '@shared/ui/Loading';

import MerchantAssets from '@merchant/Assets';
import MerchantDeposit from '@merchant/Deposit';
import MerchantWithdrawl from '@merchant/Withdrawl';
import MerchantShops from '@merchant/Shops';
import MerchantOrders from '@src/widgets/Orders';
import MerchantArchive from '@merchant/Archive';
import MerchantStatistics from '@merchant/Statistics';
import MerchantSettings from '@merchant/Settings';
import MerchantTransfers from '@merchant/Internal_transfers';
import MerchantHelp from '@merchant/Help';
import MerchantWithdrawlHistory from '@merchant/WithdrawlHistory';
import Order from '@src/widgets/Order';

const merchantRoutes = [
    { path: '/assets', element: <MerchantAssets /> },
    { path: '/deposit/shop/:shopName', element: <MerchantDeposit /> },
    { path: '/orders', element: <MerchantOrders /> },
    { path: '/shops', element: <MerchantShops /> },
    { path: '/statistics', element: <MerchantStatistics /> },
    { path: '/archive', element: <MerchantArchive /> },
    { path: '/orders/:order_id', element: <Order /> },
    { path: '/settings', element: <MerchantSettings /> },
    { path: '/assets/internal_transfers', element: <MerchantTransfers /> },
    { path: '/assets/withdrawl', element: <MerchantWithdrawl /> },
    { path: '/help', element: <MerchantHelp /> },
    { path: '/assets/withdrawl/history', element: <MerchantWithdrawlHistory /> }
];

export default merchantRoutes;


// // src/routes/merchantRoutes.js
// import React, { Suspense, lazy } from 'react';
// import { Route } from 'react-router-dom';
// import Loading from '@shared/ui/Loading';

// const MerchantAssets = lazy(() => import('@merchant/Assets'));
// const MerchantDeposit = lazy(() => import('@merchant/Deposit'));
// const MerchantWithdrawl = lazy(() => import('@merchant/Withdrawl'));
// const MerchantShops = lazy(() => import('@merchant/Shops'));
// const MerchantOrders = lazy(() => import('@src/widgets/orders'));
// const MerchantArchive = lazy(() => import('@merchant/Archive'));
// const MerchantStatistics = lazy(() => import('@merchant/Statistics'));
// const MerchantSettings = lazy(() => import('@merchant/Settings'));
// const MerchantTransfers = lazy(() => import('@merchant/Internal_transfers'));
// const MerchantHelp = lazy(() => import('@merchant/Help'));
// const MerchantWithdrawlHistory = lazy(() => import('@merchant/WithdrawlHistory'));
// const Order = lazy(() => import('@src/pages/merchant/Order'));

// const merchantRoutes = [
//     { path: '/assets', element: <MerchantAssets /> },
//     { path: '/deposit/shop/:shopName', element: <MerchantDeposit /> },
//     { path: '/orders', element: <MerchantOrders /> },
//     { path: '/shops', element: <MerchantShops /> },
//     { path: '/statistics', element: <MerchantStatistics /> },
//     { path: '/archive', element: <MerchantArchive /> },
//     { path: '/orders/:order_id', element: <Order /> },
//     { path: '/settings', element: <MerchantSettings /> },
//     { path: '/assets/internal_transfers', element: <MerchantTransfers /> },
//     { path: '/assets/withdrawl', element: <MerchantWithdrawl /> },
//     { path: '/help', element: <MerchantHelp /> },
//     { path: '/assets/withdrawl/history', element: <MerchantWithdrawlHistory />}
// ];

// export default merchantRoutes;
