// src/components/sections/Section2.js
import React, { useState, useEffect, useMemo } from 'react';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import RequestSwitcher from "@features/RequestSwitcher";
import VBox from '@shared/ui/layouts/Vbox';
import Text from "@shared/ui/Text";
import getShopsApi from '@shared/api/services/getShopsApi';
import formatReadableDate from "@shared/helpers/formateReadableDate";
import SimpleInput from "@shared/ui/Simpleinput";
import GlassCard from "@shared/ui/GlassCard";
import CopyableText from "@shared/ui/CopyableText";
import PageWrapper from '@shared/ui/layouts/PageWrapper';
import Table from "@shared/ui/Table";
import Hbox from "@shared/ui/layouts/Hbox";
import { useNavigate } from 'react-router-dom';
import PagePreloader from './elements/PagePreloader';
import switchShopStatusApi from "@shared/api/services/shops/switchShopStatusApi";
import Pagination from '@shared/ui/Pagination';
import { LoginOutlined } from '@ant-design/icons';
import useDebounce from "@shared/hooks/useDebounce";
import TodoWrapper from "@shared/ui/TodoWrapper";


const ShopsAdmin = () => {
    const [shops, setShops] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState("");
    const [shop_id, setShopId] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [shopName, setShopName] = useState("");
    const navigate = useNavigate();
    
    const debouncedUsername = useDebounce(username, 600);
    const debouncedShopName = useDebounce(shopName, 600);
    const debouncedShopId = useDebounce(shop_id, 600);

    useEffect(fetchShops, [currentPage, debouncedUsername, debouncedShopName, debouncedShopId]);

    const columns = useMemo(getColumns, [fetchShops]);

    return (
        <PageWrapper ContentPreloader={PagePreloader} loading={loading} title="Магазины">
            <Hbox wrap customJust="start" gap="10px">
                <div>
                    <SimpleInput label="Имя мерчанта" value={username} onChange={(e) => {setUsername(e.target.value)}} />
                </div>
                <div>
                    <SimpleInput label="ID магазина" value={shop_id} onChange={(e) => {setShopId(e.target.value)}} />
                </div>
                <div>
                    <SimpleInput label="Название магазина" value={shopName} onChange={(e) => {setShopName(e.target.value)}} />
                </div>
            </Hbox>
            <VBox gap='20px'>
                <GlassCard>
                    <Text weight="bold">Список магазинов</Text>
                    <Table dataSource={shops} loading={loading} columns={columns}/>
                    <Pagination value={currentPage} total={totalPages} onChange={setCurrentPage} />
                </GlassCard>
            </VBox>
        </PageWrapper>
    );

    function fetchShops() {
        (async () => {
            setLoading(true);

            try {
                const {data, total_pages} = await getShopsApi({shopname: debouncedShopName, shop_id: debouncedShopId, username: debouncedUsername, page: currentPage, shop_id});

                setShops(data);
                setTotalPages(total_pages);
            } catch(e) {
                console.log(e);
                setShops([]);
                setTotalPages(0);
                setCurrentPage(1);
            } finally {
                setLoading(false);
            }
        })();
    }

    function getColumns() {
        return [
            { title: "ID", dataIndex: "id", render: (id) => <CopyableText text={id} />, width: "1px" },
            { title: "Магазин", dataIndex: "name" },
            { title: "Фиатная валюта", dataIndex: "fiat_currency", render: (value) => value.currency },
            { title: "Цифровая валюта", dataIndex: "digital_currency", render: (value) => value.currency },
            { title: "Payin", dataIndex: "payin" },
            { title: "Payout", dataIndex: "payout" },
            { title: "Имя пользователя", dataIndex: "user", render: ({username}) => username },
            { title: "Статус (админ)", dataIndex: "admin_enabled", width: "1px", render: (value, {id}) => <RequestSwitcher request={async () => {await switchShopStatusApi(id)}} onSuccess={fetchShops} defaultValue={value} /> },
            { title: "Статус (мерчант)", dataIndex: "user_enabled", width: "1px", render: (value) => value ? "Включен" : "Выключен" },
            { title: "Создан", dataIndex: "created_at", render: (value) => formatReadableDate(value) },
            { title: "", dataIndex: "navigate_action", width: "1px", render: (_, {id}) => <SimpleButton onClick={() => {navigate(`/shops/${id}`)}} icon={<LoginOutlined />}>Перейти</SimpleButton>  },
        ];
    }
};

export default ShopsAdmin;
