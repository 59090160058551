import React, { useEffect, useState } from 'react';
import Text from "@shared/ui/Text";
import { useAuth } from '@shared/AuthContext';
import formatNumber from '@shared/helpers/formatNumber';
import {HeaderContainer, CalendarContainer, StyledPrice} from "./styles";
import Icon from "@shared/ui/icons/Icon";
import LogoutButton from '@features/LogoutButton';
import Hbox from "@shared/ui/layouts/Hbox";
import getHeaderRatesApi from '@shared/api/services/header/getHeaderRatesApi';
import getBalanceApi from "@shared/api/services/getBalanceApi";
import makeRequest from "@shared/api/makeRequest";
import { MenuOutlined } from '@ant-design/icons'; 
import ApkLink from './elements/ApkLink';
import Notifications from '@features/Notifications';


const Price = ({value, currency}) => {
  const formattedNumber = formatNumber(value);

  return <StyledPrice>
    <Text>{formattedNumber} {currency}</Text>
  </StyledPrice>
}

const Header = ({ toggleSidebar }) => {
  const {user} = useAuth();
  const [rates, setRates] = useState([]);
  const [balance, setBalance] = useState();
  const [disputes, setDisputes] = useState();

  useEffect(fetchRates, []);
  useEffect(initLoopFetching, []);
  useEffect(fetchBalance, []);

  return (
    <HeaderContainer>
      <Icon onClick={toggleSidebar} icon={MenuOutlined} />
      <div style={{display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "center", }}>
        {balance !== undefined && <Rate label="Баланс" symbol="USDT" price={balance} />}
        {rates.map(({label, symbol, price}) => <Rate key={label} label={label} symbol={symbol} price={price} />)}
        {disputes !== undefined && <Rate dec={false} label="Диспуты" symbol="шт" price={disputes} />}
      </div>
      <Hbox gap="20px">
        {user?.role === "trader" && <ApkLink />}
        <Notifications />
        <LogoutButton />
      </Hbox>
    </HeaderContainer>
  );

  function fetchRates() {
    (async () => {
      try {
        const {rates, disputes} = await getHeaderRatesApi();

        setRates(rates);
        setDisputes(disputes);
      } catch(e) {console.log(e)}
    })();
  }

  function fetchBalance() {
    (async () => {
      try {
        let balance;

        if(user.role === "merchant") {
          const {overall_statistics} = await makeRequest("/api/v1/wallets/balance/shops", { method: "GET" });
          balance = overall_statistics.total_balance;
        } else {
          const {total_balance} = await getBalanceApi();
          balance = total_balance;
        }

        setBalance(balance);
      } catch(e) {
        console.log(e);
      }
    })();
  }

  function initLoopFetching() {
    const interval = setInterval(fetchRates, 60000);

    return () => {
        clearInterval(interval);
    };
  }
};

function Rate({label, symbol, price, dec = true}) {
  return <CalendarContainer>
    <p style={{margin: '0 5px 0 0', fontSize: "12px"}}><Text>{label}</Text></p>
    {dec ? <Price currency={symbol} value={price}/> : <Text>{price}</Text>}
  </CalendarContainer>
}

export default Header;
