import Text from "@shared/ui/Text";
import styled from "styled-components";
import { Skeleton } from "@mui/material";
import { borderRadiuses, namings } from "../app/constants";
import { EditOutlined } from "@ant-design/icons";
import Hbox from "@shared/ui/layouts/Hbox";
import RequestSwitcher from "@features/RequestSwitcher";
import TodoWrapper from "@shared/ui/TodoWrapper"
import switchHubStatusApi from "@shared/api/services/hubs/switchHubStatusApi";

const height = "200px";

const GlassCard = styled.div`
    background-color: rgb(245,245,245);
    border-radius: ${borderRadiuses.default};
    ${({ turbo }) => turbo ? `
        background: rgb(131,58,180);
        background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    ` : "background: linear-gradient(90deg, #7F7FD5 0%, #86A8E7 50%, #91EAE4 100%);"}
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid ${props => props.theme.border};
    text-align: center;
    min-height: ${height};
    width: 100%;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
        filter: brightness(0.95);
    }

    &:hover .edit-button {
        opacity: 1; /* Показываем кнопку при наведении */
    }
`;

const Description = styled.div`
    border-top: solid 1px ${props => props.theme.border};
    padding: 15px;
    background: ${props => props.theme.secondary};
`;

const TurboIcon = styled.div`
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
    min-height: 20px;
    border: solid 1px ${props => props.theme.border};
    background: ${props => props.theme.secondary};
`;

const TurboContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const TextBlock = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`


const HiddenButton = ({Icon, onClick}) => {
    const Element = styled(Icon)`
        background: ${props => props.theme.secondary};
        border-radius: 5px;
        padding: 5px;
        border: solid 1px ${props => props.theme.border};
        opacity: 0;
        transition: opacity 0.3s ease; /* Плавное появление */
        cursor: pointer;
        height: fit-content;

        &:hover {
            filter: brightness(0.95);
        }
    `;

    return <Element onClick={onClick} className="edit-button"  />
}

const LimitsBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    align-items: start;
    gap: 5px;
`

// Карточка хаба, которую видит админ, когда просматривает хабы конкрентно трейдера
function AdminRecHubCard({ id, user_enabled, admin_enabled, name, description, turbo, onEdit, paymentMethod, traficMode, onOpen, ...rest }) {
    return (
        <GlassCard turbo={turbo} onClick={onOpen}>
            <TurboContainer>
                <Hbox>
                    <div style={{display: "flex", gap: "5px", flexWrap: "wrap"}}>
                        {turbo && <Info>Турбо</Info>}
                        {paymentMethod?.name && <Info>{paymentMethod.name}</Info>}
                        {traficMode?.label && !turbo && <Info>{traficMode.label}</Info>}
                        {<Info>{user_enabled ? "Включено трейдером" : "Выключено трейдером"}</Info>}
                    </div>
                    <HiddenButton
                        onClick={(e) => {e.stopPropagation(); onEdit()}}
                        Icon={EditOutlined}
                    />
                </Hbox>
                <LimitsBlock gap="5px">
                    <Info>{namings.min_transaction_amount} {rest.min_transaction_amount}</Info>
                    <Info>{namings.max_transaction_amount} {rest.max_transaction_amount}</Info>
                    <Info>{namings.max_hourly_orders} {rest.max_hourly_orders || 0}</Info>
                    <Info>{namings.max_dayly_orders} {rest.max_daily_orders || 0}</Info>
                    <Info>{namings.max_hourly_volume} {rest.max_hourly_volume || 0}</Info>
                    <Info>{namings.max_dayly_volume} {rest.max_dayly_volume || 0}</Info>
                    {/* <TodoWrapper>
                        <Info>Макс. кол-во ордеров в месяц ---------</Info>
                        <Info>Макс. объем в месяц ---------</Info>
                    </TodoWrapper> */}
                    <Info>{namings.max_active_tx} {rest.max_active_tx || 0}</Info>
                </LimitsBlock>
            </TurboContainer>
            <Description>
                <Hbox>
                    <TextBlock>{name}</TextBlock>
                    <div onClick={(e) => {e.stopPropagation()}}>
                        <RequestSwitcher defaultValue={admin_enabled} request={async () => {await switchHubStatusApi(id)}} />
                    </div>
                </Hbox>
            </Description>
        </GlassCard>
    );
}

export function RecHubCardSkeleton() {
    return <Skeleton variant="rounded" sx={{ margin: 0, padding: 0, marginTop: 0, height, borderRadius: borderRadiuses.default }} />;
}

function Info({children}) {
    return <div><TurboIcon><Hbox alignItems="center"><Text weight="bold" size="small">{children}</Text></Hbox></TurboIcon></div>
}

export default AdminRecHubCard;
