import React from "react";
import SimpleButton from "./buttons/SimpleBtn";
import { Popover } from "antd";


const App = ({ content, children, ...props }) => {
  return (
      <Popover
        content={content}
        arrow={false}
        trigger="click"
        {...props}
      >
        {children}
      </Popover>
  );
};

export default App;
