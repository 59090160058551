import { useEffect, useState } from "react";
import Select from "@shared/ui/Select";
import { Controller } from "react-hook-form";
import getPaymentMethodsByCurrencyApi from "@shared/api/services/getPaymentMethodsByCurrencyApi";


const PaymentMethodDropDown = ({control, currency_id, error}) => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(fetchPaymentMethods, [currency_id]);

    return <Controller
        name="payment_method"
        control={control}
        rules={{ required: "Обязательное поле" }}
        render={({ field }) => (
            <Select
                {...field}
                showSearch
                options={paymentMethods}
                loading={loading}
                label="Метод оплаты"
                fullWidth
                error={error}
            />
        )}
    />

    function fetchPaymentMethods() {
        (async () => {
            try {
                setLoading(true);

                const {payment_methods} = await getPaymentMethodsByCurrencyApi({currency_id});

                setPaymentMethods(payment_methods.map(normalize));
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();

        function normalize({code, name}) {
            return {value: code, label: name}
        }
    }
}

export default PaymentMethodDropDown;