import {ThreeColumns} from "@shared/ui/containers/ListContainers";
import getBalanceApi from "@shared/api/services/getBalanceApi";
import InfoCard from '@shared/ui/InfoCard';
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import React, { useState, useEffect } from 'react';
import GlassCard from "@shared/ui/GlassCard";
import Vbox from "@shared/ui/layouts/Vbox";
import TronQRCode from '@shared/ui/widgets/TronQrWidget';
import Hbox from "@shared/ui/layouts/Hbox";
import ClipboardCopy from "@shared/ui/ClipboardCopy";


const data1 = [
    { name: 'Day 1', value: 1000 },
    { name: 'Day 2', value: 1300 },
    { name: 'Day 3', value: 4000 },
    { name: 'Day 4', value: 1200 },
    { name: 'Day 5', value: 1400 },
    { name: 'Day 6', value: 2000 },
    { name: 'Day 7', value: 1700 },
    { name: 'Day 8', value: 7000 },
];

const data2 = [
    { name: 'Day 1', value: 7000 },
    { name: 'Day 2', value: 4000 },
    { name: 'Day 3', value: 4000 },
    { name: 'Day 4', value: 1200 },
    { name: 'Day 5', value: 1400 },
    { name: 'Day 6', value: 2000 },
    { name: 'Day 7', value: 1700 },
    { name: 'Day 8', value: 1000 },
];


const Platform = () => {
    const [balance, setBalance] = useState(0);
    const [profit, setProfit] = useState(0);
    const [trx_balance, setTRXBalance] = useState(0);
    const [trx_address, setTRXAddress] = useState(0);

    useEffect(fetchBalance, []);

    return <GlassCard>
        <Text weight="bold">Основные балансы</Text>
        <ThreeColumns>
            <InfoCard data={data1} currency="USDT" title="Баланс площадки" value={balance} />
            <InfoCard data={data2} currency="USDT" title="Профит площадки" value={profit} />
            <ElementCard height="fit-content">
                <Hbox gap="10px">
                    <Vbox gap="10px">
                        <Text>Баланс trx</Text>
                        <Text weight="bold" size="medium">{trx_balance} TRX</Text>
                        <ClipboardCopy value={trx_address} />
                    </Vbox>
                    <TronQRCode address={trx_address} />
                </Hbox>
            </ElementCard>
        </ThreeColumns>
    </GlassCard>

    function fetchBalance() {
        (async () => {
            try {
                const {total_balance, user_balance, trx_balance, trx_address} = await getBalanceApi();
            
                setBalance(total_balance);
                setProfit(user_balance?.total_balance);
                setTRXAddress(trx_address);
                setTRXBalance(trx_balance);
            } catch(e) {
                console.log(e)
            }
        })();
    }
}

export default Platform;