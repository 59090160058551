import Text from "@shared/ui/Text";
import styled from "styled-components";
import Skeleton from "@shared/ui/Skeleton";
import { borderRadiuses, namings } from "../app/constants";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Hbox from "@shared/ui/layouts/Hbox";
import switchHubStatusApi from "@shared/api/services/hubs/switchHubStatusApi";
import RequestSwitcher from "@features/RequestSwitcher";
import TodoWrapper from "@shared/ui/TodoWrapper";


const height = "200px";

const GlassCard = styled.div`
    background-color: rgb(245,245,245);
    border-radius: ${borderRadiuses.default};
    ${({ turbo }) => turbo ? `
        background: rgb(131,58,180);
        background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    ` : "background: linear-gradient(90deg, #7F7FD5 0%, #86A8E7 50%, #91EAE4 100%);"}
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid ${props => props.theme.border};
    text-align: center;
    min-height: ${height};
    width: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        filter: brightness(0.95);
    }

    &:hover .edit-button {
        opacity: 1; /* Показываем кнопку при наведении */
    }
`;

const Description = styled.div`
    border-top: solid 1px ${props => props.theme.border};   
    padding: 15px;
    background: ${props => props.theme.secondary};
`;

const TurboIcon = styled.div`
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
    min-height: 20px;
    border: solid 1px ${props => props.theme.border};
    background: ${props => props.theme.secondary};
`;

const TurboContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;


const HiddenButton = ({Icon, onClick}) => {
    const Element = styled(Icon)`
        background: ${props => props.theme.secondary};
        border-radius: 5px;
        padding: 5px;
        border: solid 1px ${props => props.theme.border};
        opacity: 0;
        transition: opacity 0.3s ease; /* Плавное появление */
        cursor: pointer;

        &:hover {
            filter: brightness(0.95);
        }

        height: fit-content;
    `;

    return <Element onClick={(e) => {e.stopPropagation(); onClick(e)}} className="edit-button"  />
}

const LimitsBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    align-items: start;
    gap: 5px;
`

const TextBlock = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`


function RecHubCard({ id, name, description, user_enabled, admin_enabled, turbo, onEdit, onOpen, paymentMethod, traficMode, onDelete, ...rest }) {
    return (
        <GlassCard turbo={turbo} onClick={onOpen}>
            <TurboContainer>
                <Hbox gap="10px">
                    <div>
                        <Hbox customJust="start" wrap gap="10px">
                            {turbo && <Info>Турбо</Info>}
                            {paymentMethod?.name && <Info>{paymentMethod.name}</Info>}
                            {traficMode?.label && !turbo && <Info>{traficMode.label}</Info>}
                            {!admin_enabled && <Info>Выключено админом</Info>}
                        </Hbox>
                    </div>
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <HiddenButton
                            onClick={onEdit}
                            Icon={EditOutlined}
                        />
                        <HiddenButton
                            onClick={onDelete}
                            Icon={DeleteOutlined}
                        />                
                    </div>
                </Hbox>
                <LimitsBlock gap="5px">
                    <Info>{namings.min_transaction_amount} {rest.min_transaction_amount || 0}</Info>
                    <Info>{namings.max_transaction_amount} {rest.max_transaction_amount || 0}</Info>
                    <Info>{namings.max_hourly_orders} {rest.max_hourly_orders || 0}</Info>
                    <Info>{namings.max_dayly_orders} {rest.max_daily_orders || 0}</Info>
                    <Info>{namings.max_hourly_volume} {rest.max_hourly_volume || 0}</Info>
                    <Info>{namings.max_active_tx} {rest.max_active_tx || 0}</Info>
                    {/* <TodoWrapper>
                        <Info>Payout ставка 5%</Info>
                        <Info>Payin ставка 2%</Info>
                    </TodoWrapper> */}
                </LimitsBlock>
            </TurboContainer>
            <Description>
                <Hbox>
                    <TextBlock>{name}</TextBlock>
                    <div onClick={(e) => {e.stopPropagation()}}>
                        <RequestSwitcher defaultValue={user_enabled} request={async () => {await switchHubStatusApi(id)}} />
                    </div>
                </Hbox>
            </Description>
        </GlassCard>
    );
}

export function RecHubCardSkeleton() {
    return <Skeleton width="100%" height={height} />;
}

function Info({children}) {
    return <div><TurboIcon><Hbox alignItems="center"><Text weight="bold" size="small">{children}</Text></Hbox></TurboIcon></div>
}

export default RecHubCard;
