import PageWrapper from "@shared/ui/layouts/PageWrapper"
import Hbox from "@shared/ui/layouts/Hbox";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import getShopApi from "@shared/api/services/shops/getShopApi";
import GlassCard from "@shared/ui/GlassCard";
import getUserInfoApi from "@shared/api/services/admin/getUserInfoApi";
import Linkeds from "./elements/Linkeds";
import Unlinkeds from "./elements/Unlinkeds";
import ContentPreloader from "./ContentPreloader";
import Select from "@shared/ui/Select";
import useSearchParams from "@shared/hooks/useSearchParams";
import {TwoColumns} from "@shared/ui/containers/ListContainers";
import getUnlinkedHubShopAssosiationsApi from "@shared/api/services/assosiations/getUnlinkedHubShopAssosiationsApi";
import getUnlinkedPayoutHubShopAssosiations from "@shared/api/services/assosiations/getUnlinkedPayoutHubShopAssosiations";
import getLinkedHubShopAssosiationsApi from "@shared/api/services/assosiations/getLinkedHubShopAssosiationsApi";
import getLinkedPayoutHubShopAssosiations from "@shared/api/services/assosiations/getLinkedPayoutHubShopAssosiations";
import styled from "styled-components";


export const types = [
    { label: "Payin", value: "payin" },
    { label: "Payout", value: "payout" },
];

const TableWrapper = styled.div`
  width: 100%; /* Ограничение ширины контейнера */
  overflow-x: auto; /* Горизонтальная прокрутка, если контент выходит за пределы */
`;

// Страница на которой показываются все Хабы конкрентного трейдера по отношению к ранее выбранному магазину (привязанные и непривязанные)
const TradersAssosiations = ({}) => {
    const {searchParams, updateSearchParams} = useSearchParams();
    const {shop_id, trader_id} = useParams();
    const [loading, setLoading] = useState(true); // Загрузка имени Трейдера
    const [shopData, setShopData] = useState({});
    const [username, setUsername] = useState("");
    const type = searchParams.get("type") || types[0].value;

    const [linkedsLoading, setLinkedsLoading] = useState(false);
    const [linkeds, setLinkeds] = useState([]);
    const [linkedsTotalPages, setLinkedsTotalPages] = useState(1);
    const [linkedsCurrentPage, setLinkedsCurrentPage] = useState(1);

    const [unlinkedsLoading, setUnlinkedsLoading] = useState(false);
    const [unlinkeds, setUnlinkeds] = useState([]);
    const [unlinkedsTotalPages, setUnlinkedsTotalPages] = useState(1);
    const [unlinkedsCurrentPage, setUnlinkedsCurrentPage] = useState();

    useEffect(fetchUsername, []);
    useEffect(fetchShopName, []);

    const navigate = useNavigate();

    useEffect(fetchData, [type]);
    
    const title = `Просмотр хабов трейдера "${username}"`;
    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/shops")}}>Магазины</a> },
        { title: <a onClick={() => {navigate(`/shops/${shop_id}`)}}>Магазин "{shopData.name || ""}"</a> }
    ]

    return <PageWrapper breadcrumbs={breadcrumbs} ContentPreloader={ContentPreloader} loading={loading} title={title}>
        <Hbox width="300px" gap="10px">
            <Select value={type} options={types} onChange={(type) => {updateSearchParams({type})}} />
        </Hbox>
        <GlassCard>
            <TwoColumns>
                <TableWrapper>
                    <Linkeds setCurrentPage={setLinkedsCurrentPage} currentPage={linkedsCurrentPage} onSuccess={fetchData} items={linkeds} totalPages={linkedsTotalPages} loading={linkedsLoading} traderName={username} />
                </TableWrapper>
                <TableWrapper>
                    <Unlinkeds setCurrentPage={setUnlinkedsCurrentPage} currentPage={unlinkedsCurrentPage} onSuccess={fetchData} items={unlinkeds} totalPages={unlinkedsTotalPages} loading={unlinkedsLoading} traderName={username} />  
                </TableWrapper>
            </TwoColumns>
        </GlassCard>
    </PageWrapper>

    function fetchUsername() {
        (async () => {
            setLoading(true);

            try {
                const {username} = await getUserInfoApi(trader_id);

                setUsername(username);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }    

    function fetchShopName() {
        (async () => {
            try {
                setLoading(true);

                const data = await getShopApi(shop_id);

                setShopData(data);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }

    function fetchData() {
        fetchLinkeds();
        fetchUnlinkeds();
    }

    function fetchLinkeds() {
        (async () => {
            if(!shop_id || !trader_id) return;

            try {
                setLinkedsLoading(true);

                const {data, total_pages} = await fetchHubs();

                async function fetchHubs() {
                    return type === "payin" ? 
                        await getLinkedHubShopAssosiationsApi(shop_id, trader_id)
                        :
                        await getLinkedPayoutHubShopAssosiations(shop_id, trader_id)
                }

                setLinkeds(data);
                setLinkedsTotalPages(total_pages);
            } catch(e) {
                console.log(e);
            } finally {
                setLinkedsLoading(false);
            }
        })();
    }

    function fetchUnlinkeds() {
        (async () => {
            if(!shop_id || !trader_id) return;

            setUnlinkedsLoading(true);

            try {
                const {data, total_pages} = await fetchHubs();

                setUnlinkeds(data);
                setUnlinkedsTotalPages(total_pages);

                async function fetchHubs() {
                    return type === "payin" ?
                        await getUnlinkedHubShopAssosiationsApi(shop_id, trader_id)
                        :
                        await getUnlinkedPayoutHubShopAssosiations(shop_id, trader_id)
                }
            } catch(e) {
                console.log(e)
            } finally {
                setUnlinkedsLoading(false);
            }
        })();
    }
}

export default TradersAssosiations;