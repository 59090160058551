import { useEffect, useState } from "react";
import GlassCard from "@shared/ui/GlassCard";
import Text from "@shared/ui/Text";
import makeRequest from "@shared/api/makeRequest";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import Hbox from "@shared/ui/layouts/Hbox";


const TelegramLogin = () => {
    const [status, setStatus] = useState(false);
    const botUsername = process.env.REACT_APP_BOT_USERNAME;
    const [accounts, setAccounts] = useState([]);

    useEffect(fetchAccounts, []);

    const TelegramWidget = () => {
        useEffect(() => {
            if (document.getElementById("telegram-widget")) {
                document.getElementById("telegram-widget").innerHTML = "";
            }
            delete window.handleTelegramAuth;

            window.handleTelegramAuth = ({ id }) => setId(id);

            const script = document.createElement("script");
            script.src = "https://telegram.org/js/telegram-widget.js?22";
            script.async = true;
            script.setAttribute("data-telegram-login", botUsername);
            script.setAttribute("data-size", "large");
            script.setAttribute("data-onauth", "handleTelegramAuth(user)");
            script.setAttribute("data-request-access", "write");

            document.getElementById("telegram-widget").appendChild(script);

            return () => {
                if (document.getElementById("telegram-widget")) {
                    document.getElementById("telegram-widget").innerHTML = "";
                }
                delete window.handleTelegramAuth;
            };
        }, [botUsername]);

        return <div id="telegram-widget"></div>;
    };

    return (
        <GlassCard height="fit-content">
            <Text weight="bold">Привязанные аккаунты</Text>
            {accounts.map(telegramId => <TgAccount telegramId={telegramId} />)}
            <Text>Статус - {status ? "Телеграмм привязан" : "Телеграмм не привязан"}</Text>
            <TelegramWidget />
        </GlassCard>
    );

    function setId(telegram_id) {
        (async () => {
            try {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/core/users/telegram/add`, {
                        body: { telegram_id: telegram_id.toString() },
                    });
                    fetchAccounts();
                }, {
                    successText: "Аккаунт успешно привязан",
                    loadingText: "Добавление аккаунта",
                    errorText: "Ошибка добавления аккаунта"
                });
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function fetchAccounts() {
        (async () => {
            try {
                const {data} = await makeRequest("/api/v1/core/users/telegram/get", { method: "GET" });

                setAccounts(data || []);
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function unlinkAccount(id) {
        (async () => {
            try {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/core/users/telegram/remove?telegram_id=${id}`, { method: "GET" });
                    fetchAccounts();
                }, {
                    successText: "Успешно отвязан",
                    loadingText: "Отвязка аккаунта",
                    errorText: "Ошибка отвязки"
                });
            } catch (e) {
                console.log(e);
            }
        })();
    }

    function TgAccount({telegramId}) {
        return <Hbox gap="10px">
            <div>{telegramId}</div>
            <SimpleButton onClick={() => {unlinkAccount(telegramId)}}>Отвязать</SimpleButton>
        </Hbox>
    }
};



export default TelegramLogin;

