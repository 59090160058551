import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import {useAuth} from "@shared/AuthContext";
import OpenDisputeModal from "@features/OpenDisputeModal";
import {useModal} from "@shared/ModalContext";
import Vbox from "@shared/ui/layouts/Vbox";
import Text from "@shared/ui/Text";
import { useMemo, useState } from "react";
import ReceiptsUploader from "@features/ReceiptUploader";
import makeRequest from "@shared/api/makeRequest";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import ElementCard from "@shared/ui/ElementCard";


const TraderActions = ({orderData, onSuccess}) => {
    const {user} = useAuth();
    const {openModal} = useModal();
    const [filesIds, setFilesIds] = useState([]);
    const payinMenuItems = useMemo(getPayinMenuItems, [orderData, user]);
    const payoutMenuItems = useMemo(getPayoutMenuItems, [orderData, user]);

    const payment_type = orderData.payment_type;
    const menuItems = payment_type === "payout" ? payoutMenuItems : payinMenuItems;

    const addFileId = (fileId) => {
        setFilesIds(prev => [...new Set([...prev, fileId])]);
    }

    if(menuItems?.length < 1) return;

    return <ElementCard>
        <Vbox gap="10px">
            <Text weight="bold">Действия</Text>
            {menuItems.map(({label, onClick}) => <SimpleButton key={label} onClick={onClick}>{label}</SimpleButton>)}
            {orderData.status?.name === "dispute" || (orderData.status?.name === "active" && payment_type === "payout") ? <ReceiptsUploader onSuccess={(fileId) => {
                onSuccess();
                addFileId(fileId);
            }} transaction_id={orderData.id} /> : <></>}
        </Vbox>
    </ElementCard>

    function getPayoutMenuItems() {
        return {
            dispute: [],
            successful: [],
            active: [
                ConfirmTransactionExecution()
            ],
            canceled: [],
        }[orderData.status?.name] || []

        function ConfirmTransactionExecution() {
            return getButton('Подтвердить исполнение транзакции', confirmApi);

            async function confirmApi() {
                await handleAsyncNotification(async () => {
                    await makeRequest("/api/v1/orders/transactions/payout/payment/proof", {
                        body: {
                            transaction_id: orderData.id,
                            receipts: filesIds
                        }
                    })
                    onSuccess();
                },{
                    loadingText: "Подтверждение исполнения транзакции",
                    errorText: "Ошибка подтверждения исполнения транзакции",
                    successText: "Исполнение транзакции подтверждено"
                });
            }
        }
    }

    function getPayinMenuItems() {
        const items = {
            dispute: {
                any: [],
                receipt_verification_by_trader: [
                    ConfirmPayment(),
                    Refute()
                ],
                video_confirmation_is_requested: [
                    VideoUploaded()
                ]
            },
            successful: {
                any: [
                    OpenDispute()
                ]
            },
            active: {
                any: [
                    OpenDispute()
                ]
            },
            canceled: {
                any: [
                    OpenDispute()
                ]
            },
        }
        const statusItems = items[orderData.status?.name];
        
        if(!statusItems) return [];

        const subreasonItems = statusItems[orderData.subreason?.code] || [];

        return subreasonItems; // Кнопки у админа и сапорта одинаковые

        function OpenDispute() {
            return getButton('Открыть диспут', () => {openModal(<OpenDisputeModal onSuccess={onSuccess} transaction={orderData}/>, 'Открытие диспута')}) 
        }

        function ConfirmPayment() {
            return getButton('Подтвердить поступление', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/trader/confirm?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess()
                }, {
                    successText: "Подтверждено поступление",
                    errorText: "Ошибка подтверждения поступления",
                    loadingText: "Подтверждение"
                })
            })
        }

        function Refute() {
            return getButton('Оспорить', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/trader/refute?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess();
                }, {
                    successText: "Оспорено",
                    errorText: "Ошибка оспаривания",
                    loadingText: "Оспаривание"
                })
            })
        }

        function VideoUploaded() {
            return getButton('Выслал видеодоказательство', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/trader/video/uploaded?transaction_id=${orderData.id}`, {method: "GET"});
                    onSuccess();
                }, {
                    successText: "Выполнено",
                    errorText: "Ошибка",
                    loadingText: "Сохранение"
                })
            })
        }
    };

    function getButton(label, onClick) {
        return {
            label,
            onClick,
        }
    }
}

export default TraderActions;