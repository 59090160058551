// src/components/sections/Section1.js
import React, { useEffect, useState } from 'react';
import GlassCard from "@shared/ui/GlassCard";
import Text from "@shared/ui/Text";
import makeRequest from "@shared/api/makeRequest";
import TurnOffAuth from './elements/TurnOffAuth';
import TurnOnAuth from './elements/TurnOnAuth';


const TwoFactorAuth = () => {
    const [status, setStatus] = useState(false);

    useEffect(fetchAuthStatus, []);
    
    return <GlassCard height="fit-content">
        <Text weight="bold">Двухфакторная аутентификация</Text>
        
        {status ? <TurnOffAuth setStatus={setStatus} /> : <TurnOnAuth setStatus={setStatus} />}     
    </GlassCard>

    function fetchAuthStatus() { // Узнаем, Включена ли 2FA авторизация
        (async () => {
            try {
                const [status] = await makeRequest("/api/v1/core/2fa/check", { method: "GET" });

                setStatus(status);
            } catch(e) {
                console.log(e);
            }
        })();
    }
};

export default TwoFactorAuth;
