import { Button as AntdButton } from "antd";
import styled from "styled-components";


const Button = styled(AntdButton)`
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  padding: 8px 16px;
  width: fit-content;
`;

export default Button;