import makeRequest from "@shared/api/makeRequest";


export default async function(shop_id, user_id) {
    const response = await makeRequest(`/api/v1/orders/shop/${shop_id}/hubs/payout/linked?user_id=${user_id}`, { method: "GET" });

    return {
        ...response,
        data: response.data.map(el => ({
            id: el?.id,
            hub_name: el?.payout_hub?.name,
            rate: el?.rate,
            admin_enabled: el?.payout_hub?.admin_enabled,
            max_transaction_amount: el?.payout_hub?.max_transaction_amount,
            max_active_orders: el?.payout_hub?.max_active_orders
        }
    ))}
}