export const sizes = {
    title: "2.5rem",
    pretitle: "2rem",
    subtitle: "1.5rem",
    xsmall: "10px",
    small: "12px",
    default: "14px",
    subheader: "18px",
    medium: "24px",
    large: "32px",
    xlarge: "40px",
    card: "1rem",
    subtext: "0.8rem"
}

export const weights = {
    default: "400",
    semibold: "500",
    bold: "600"
}

export const spacing = {
    default: "0.5px"
}

export const aligns = {
    basic: "justify",
    card: "center",
    subtext: "justify"
}

export const borderRadiuses = {
    default: "15px"
}

export const namings = {
    max_hourly_volume: "Макс. объем в час",
    max_dayly_volume: "Макс. объем в сутки",
    max_dayly_orders: "Макс. кол-во ордеров в сутки",
    max_hourly_orders: "Макс. кол-во ордеров в час",
    max_transaction_amount: "Макс. объем транзации", // ???????????
    min_transaction_amount: "Мин. объем транзакции",
    max_active_tx: "Макс. кол-во активных транзакций"
}

export const transactionStatuses = [
    { label: 'Любой', value: 'all' },
    { label: 'Диспут', value: 'dispute' },
    { label: 'Активный', value: 'active' },
    { label: 'Успешный', value: 'successful' },
    { label: 'Отмененный', value: 'canceled' },
    { label: 'Ожидание', value: 'pending' },
    { label: 'Проверка', value: 'validation' },
];

export const allTransactionStatuses = {
    attention: {
        payin: [ { label: 'Диспут', value: 'dispute' } ],
        payout: [ { label: 'Проверка', value: 'validation' } ]
    },
    all: {
        payin: [
            { label: 'Любой', value: 'all' },
            { label: 'Диспут', value: 'dispute' },
            { label: 'Активный', value: 'active' },
            { label: 'Успешный', value: 'successful' },
            { label: 'Отмененный', value: 'canceled' },
            { label: 'Ожидание', value: 'pending' },
            { label: 'Проверка', value: 'validation' },
        ],
        payout: [
            { label: 'Любой', value: 'all' },
            { label: 'Диспут', value: 'dispute' },
            { label: 'Активный', value: 'active' },
            { label: 'Успешный', value: 'successful' },
            { label: 'Отмененный', value: 'canceled' },
            { label: 'Ожидание', value: 'pending' },
            { label: 'Проверка', value: 'validation' },
        ]
    }
}

export const transactionTypes = [
    { label: "Payin", value: "payin" },
    { label: "Payout", value: "payout" }
]

export const attentionStatuses = {
    green: "Срочность 4",
    yellow: "Срочность 3",
    orange: "Срочность 2",
    red: "Срочность 1"
}