import React, { useState, useEffect } from 'react';
import HBox from '@shared/ui/layouts/Hbox';
import SimpleButton from '@shared/ui/buttons/SimpleBtn';
import Text from "@shared/ui/Text";
import makeRequest from "@shared/api/makeRequest";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import CopyableText from "@shared/ui/CopyableText";
import { initialValues } from './lib/constants';
import {fiatCurrencies, digitalCurrencies} from "@shared/constants";
import SimpleInput from '@shared/ui/Simpleinput';
import Select from "@shared/ui/Select";
import VBox from "@shared/ui/layouts/Vbox";
import { PlusOutlined } from '@ant-design/icons';
import getFiatCurrenciesApi from '@shared/api/services/getFiatCurrenciesApi';


const CreateShopModal = ({getShops}) => {
    const [formData, setFormData] = useState(initialValues.formData);
    const [errors, setErrors] = useState(initialValues.errors);
    const [loading, setLoading] = useState(false);
    const [shopData, setShopData] = useState(null); // Информация о созданном магазине (приходит с бека). Если есть, то показываем ее. Если нет, то форму по созданию
    const [fiatCurrencies, setFiatCurrencies] = useState([]);
    // const [digitalCurrencies, setDigitalCurrencies] = useState([]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };
    const handleDropdownSelect = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = async () => {

        try {
            setLoading(true);
            const newErrors = {
                name: !formData.name,
                websiteurl: !formData.websiteurl,
                websiteip: !formData.websiteip,
                digitalcurrency: !formData.digitalcurrency,
                fiatcurrency: !formData.fiatcurrency,
            };
    
            setErrors(newErrors);
    
            // Проверка, если есть ошибки
            const hasErrors = Object.values(newErrors).some(error => error);
            if (!hasErrors) {
                // Здесь выполняем отправку данных, если нет ошибок
                await handleAsyncNotification(async () => {
                    const data = await makeRequest('/api/v1/core/shop/create_shop/', {
                        body: formData
                    });
                    setShopData(data);
                    await getShops()
                }, {    
                    loadingText: "Создание магазина",
                    successText: "Магазин успешно создан",
                    errorText: "Ошибка создания магазина"
                })
                
            } else {
                console.log('Please fill in all required fields.');
            }
        } catch(e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    };

    function handleRulesChange(paymentrules) {
        setFormData(prev => ({...prev, paymentrules}))
    }

    useEffect(fetchFiatCurrencies, []);
    // useEffect(fetchDigitalCurrencies, []);

    return (
        <>
            {!shopData ? <>
                <HBox gap='50px'>
                <VBox width='100%' gap='10px'>
                    <HBox gap="10px">
                        <SimpleInput error={errors.name && "Обязательное поле"} name="name" label="Название" placeholder='Название' value={formData.name} onChange={handleInputChange} />
                        <SimpleInput error={errors.websiteurl && "Обязательное поле"} name="websiteurl" label="URL сайта" placeholder='https://www.example.com/' value={formData.websiteurl} onChange={handleInputChange} />
                    </HBox>
                    <HBox gap="10px">
                        <Select fullWidth error={errors.digitalcurrency} label="Цифровая валюта" value={formData.digitalcurrency} options={digitalCurrencies} onChange={(value) => handleDropdownSelect('digitalcurrency', value)} />    
                        <Select fullWidth error={errors.fiatcurrency} label="Фиатная валюта" value={formData.fiatcurrency} name="fiatcurrency" options={fiatCurrencies} onChange={(value) => handleDropdownSelect('fiatcurrency', value)} />
                    </HBox>
                    <SimpleInput error={errors.websiteip && "Обязательное поле"} label="IP сайта" name="websiteip" placeholder='IP' value={formData.websiteip} onChange={handleInputChange} hasError={errors.websiteip} />
                </VBox>
                </HBox>
                <HBox customJust='end'>
                    <SimpleButton loading={loading} main icon={<PlusOutlined />} children='Создать' onClick={handleSubmit} />
                </HBox>
            </>
            :
            <>
                <Text>Сохраните следующую информацию для доступа</Text>
                <Text size="small">Получить к ней доступ в будущем повторно невозможно</Text>
                <Text weight="bold">API_KEY: {shopData.api_key}</Text>
                <Text weight="bold">SECRET_KEY: <CopyableText text={shopData.secret_key} /></Text>
            </>
            }
        </>
    );

    function fetchFiatCurrencies() {
        (async () => {
            try {
                const fiatCurrencies = await getFiatCurrenciesApi();
                const fiatCurrenciesOptions = fiatCurrencies.map(({id, currency}) => ({value: id, label: currency}));

                setFiatCurrencies(fiatCurrenciesOptions);
            } catch(e) {
                console.log(e)
            }
        })();
    }

    // function fetchDigitalCurrencies() {
    //     (async () => {
    //         try {
    //             const digitalCurrencies = await getDigitalCurrenciesApi();
    //             const digitalCurrenciesOptions = digitalCurrencies.map(({id, currency}) => ({value: id, label: currency}));

    //             setDigitalCurrencies(digitalCurrenciesOptions);
    //         } catch(e) {
    //             console.log(e)
    //         }
    //     })();
    // }
};

export default CreateShopModal;