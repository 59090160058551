import Result from "../components/Result";
import VBox from "../components/Vbox";
import Button from "../components/Button";

const SuccessBlock = ({link}) => {
  return <VBox height="100vh" customJust="center" alignItems="center">
    <Result 
        status="success"
        title="Ссылка на переход готова"
        subTitle="Если новая вкладка не открылась автоматически, то откройте ее вручную"
        extra={<Button type="primary" onClick={() => {window.open(link, "_blank")}}>Перейти по ссылке</Button>}
    />
  </VBox>
};

export default SuccessBlock;
