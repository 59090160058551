import PageWrapper from "@shared/ui/layouts/PageWrapper";
import makeRequest from "@shared/api/makeRequest";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Vbox from "@shared/ui/layouts/Vbox"
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import Table from "@shared/ui/Table";
import Button from "@shared/ui/buttons/SimpleBtn";
import {useModal} from "@shared/ModalContext";
import Hbox from "@shared/ui/layouts/Hbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import deleteHubShopAssosiationApi from "@shared/api/services/assosiations/deleteHubShopAssosiationApi";
import Pagination from "@shared/ui/Pagination";
import { namings } from "@app/constants";
import EditHubLimitsModal from "@features/EditHubLimitsModal";
import TruncateText from "@shared/ui/TruncateText";
import Select from "@shared/ui/Select";
import useSearchParams from "@shared/hooks/useSearchParams";


const getColumns = (openUnlinkModal, openEditHubLimitsModal) => {
    return [
        { title: "Название", dataIndex: "name", render: (value) => <TruncateText text={value} maxLength={20} /> },
        { title: "Трейдер", dataIndex: "trader_name" },
        { title: "Ставка", dataIndex: "rate" },
        { title: "Турбо", dataIndex: "turbo" , render: (value) => value ? "Включен" : "Не включен" },
        { title: namings.max_hourly_orders, dataIndex: "max_hourly_orders" },
        { title: namings.max_dayly_volume, dataIndex: "max_daily_volume" },
        { title: namings.max_hourly_volume, dataIndex: "max_hourly_volume" },
        { title: namings.min_transaction_amount, dataIndex: "min_transaction_amount" },
        { title: namings.max_transaction_amount, dataIndex: "max_transaction_amount" },
        { title: namings.max_active_orders, dataIndex: "max_active_orders" },
        { title: "", dataIndex: "_", render: (_, {id}) => <SimpleButton onClick={() => {openEditHubLimitsModal(id)}}>Править лимиты</SimpleButton>, width: "1px" },
        { title: "", dataIndex: "_", width: "1px", render: (_, {hub_shop_association_id}) => <Button onClick={() => {openUnlinkModal(hub_shop_association_id)}}>Отвязать</Button>}
    ];
};

const types = [
    { label: "Payin", value: "payin" },
    { label: "Payout", value: "payout" }
]

const LinkedHubs = () => {
    const {searchParams, updateSearchParams} = useSearchParams();
    const type = searchParams.get("type") || types[0].value;
    const {shop_id} = useParams();
    const [linkeds, setLinkeds] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;
    const offset = (currentPage - 1) * limit;
    const {openModal} = useModal();
    const columns = useMemo(() => getColumns(openUnlinkModal, openEditHubLimitsModal), []);

    useEffect(fetchLinkedHubs, [type, limit, offset, shop_id]);

    return <ElementCard>
        <Text weight="bold">Удалить связь</Text>
        <Hbox>
            <Select value={type} onChange={(type) => {updateSearchParams({type})}} options={types} label="Тип хаба" />
        </Hbox>
        <Table
            columns={columns}
            dataSource={linkeds}
        />
        <Pagination 
            total={totalPages}
            value={currentPage}
            onChange={setCurrentPage}
        />
    </ElementCard>

    function fetchLinkedHubs() {
        (async () => {
            try {
                const {data, total} = await getPayinConnectedHubs();

                setTotalPages(total);
                setLinkeds(data);

                async function getPayinConnectedHubs() {
                    return await makeRequest(`/api/v1/core/hubs/${type}-connected/${shop_id}?limit=${limit}&offset=${offset}`, {
                        method: "GET"
                    });
                }
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function openUnlinkModal(assosiation_id) {
        openModal(<UnlinkModal assosiation_id={assosiation_id} onSuccess={fetchLinkedHubs}/>, "Удаление связи");
    }

    function openEditHubLimitsModal(hubId) {
        openModal(<EditHubLimitsModal hub_id={hubId} onSuccess={fetchLinkedHubs} />)
    }
}

function UnlinkModal({onSuccess, assosiation_id, closeModal}) {
    return <Vbox gap="10px">
        <Text>Подтвердите удаление связи</Text>
        <Hbox>
            <SimpleButton onClick={deleteHubShopAssosiation}>Удалить</SimpleButton>
        </Hbox>
    </Vbox>

    async function deleteHubShopAssosiation() {
        closeModal();
        try {
            await handleAsyncNotification(async () => {
                await deleteHubShopAssosiationApi(assosiation_id);
                onSuccess();
            }, {
                successText: "Связь удалена",
                errorText: "Ошибка удаления связи",
                loadingText: "Удаление связи"
            });
        } catch(e) {
            console.log(e);
        }
    }
}

export default LinkedHubs;