import { NotificationOutlined } from "@ant-design/icons";
import Icon from "@shared/ui/icons/Icon";
import Popup from "@shared/ui/Popup";
import Vbox from "@shared/ui/layouts/Vbox";
import Notification from "@shared/ui/Notification";
import { Badge } from "antd";
import makeRequest from "@shared/api/makeRequest";
import { useEffect, useState, useRef, useCallback } from "react";
import Pagination from "@shared/ui/Pagination";
import { useWebsocket } from "@shared/hooks/useWebsocket";
import styled from "styled-components";
import { toast } from "react-toastify";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";


const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  gap: 10px;
  padding: 0 5px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
`;

const MainContainer = styled.div`
  height: 400px;
  flex-direction: column;
  justify-content: space-between;
`;


const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const websocket = useWebsocket();

    const messagesContainerRef = useRef(null);
    const dummyRef = useRef(null);
    const heightRef = useRef(0);

    const scrollToBottom = useCallback(scrollToBottomCallback, []);
    const restoreScrollPosition = useCallback(restoreScrollPositionCallback, [scrollToBottom]);

    useEffect(fetchNotifications, []);
    useEffect(() => {
        restoreScrollPosition(heightRef.current);
    }, [notifications, restoreScrollPosition]);

    useEffect(() => {
        if (websocket) {    
            websocket.onmessage = (res) => {
                const {data, event, user_id} = JSON.parse(res.data);
            
                if(event === "notification") {
                    data.forEach(({message}) => {
                        toast(<div>{removeTags(message)}</div>)
                    })
                    fetchNotifications()
                }
            };
        }
    },[websocket]);

    return <Badge size="small">
        <Popup arrow={false} content={<MainContainer>
            <ListContainer ref={messagesContainerRef} onScroll={handleScroll} gap="10px">
                {notifications?.length < 1 && "Нет уведомлений"}
                {notifications.map(({text, date, viewed}) => <Notification viewed={viewed} date={date}>{text}</Notification>)}
                <div ref={dummyRef} />
            </ListContainer>
        </MainContainer>}>
            <Icon icon={NotificationOutlined} />
        </Popup>
    </Badge>

    function fetchNotifications() {
        (async () => {
            try {
                const offset_start = findOldestElement(notifications)?.date;
                const {data, total, total_pages} = await makeRequest(`/api/v1/notify/get_history${offset_start ? `?offset_start=${offset_start}` : ""}`, { method: "GET" });

                setTotalPages(total_pages);
                setNotifications(prev => {
                    const sorted = sortByDate(data);
                    const withNoDuplicates = removeDuplicatesById(sorted);
                    return [...prev, ...withNoDuplicates];
                });

                function sortByDate(array) {
                    return array.sort((a, b) => new Date(b.date) - new Date(a.date));
                }                
                function removeDuplicatesById(array) {
                    const seenIds = new Set();
                    
                    return array.filter(item => {
                        if (seenIds.has(item.date)) {
                            return false;
                        } else {
                            seenIds.add(item.date);
                            return true;
                        }
                    });
                }
                function findOldestElement(array) {
                    if (array.length === 0) return null; // Проверка на пустой массив
                
                    return array.reduce((oldest, current) => {
                        return new Date(current.date) < new Date(oldest.date) ? current : oldest;
                    });
                }
                
            } catch(e) {
                console.log(e)
            }
        })()
    }

    function handleScroll() {
        const container = messagesContainerRef.current;
    
        if (!container) return;
    
        // Определяем расстояние до низа
        const distanceToBottom = container.scrollHeight - container.scrollTop - container.clientHeight;
    
        // Если до конца страницы осталось 200px или меньше, загружаем новые уведомления
        if (distanceToBottom <= 200 && currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }
    

    function restoreScrollPositionCallback(prevHeight) {
        const container = messagesContainerRef.current;

        if (container?.scrollTop === 0) {
            container.scrollTop = container.scrollHeight - prevHeight;
        }
    }

    function scrollToBottomCallback() {
        if (dummyRef.current) {
            dummyRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    function removeTags(str) {
        return str?.replace(/<[^>]*>/g, '');  // Удаляем все, что заключено в угловые скобки
    }
}

export default Notifications;