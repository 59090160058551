import Vbox from "@shared/ui/layouts/Vbox";
import { useState, useEffect } from "react";
import Skeleton from "../Skeleton";
import Breadcrumb from "@shared/ui/Breadcrumb";
import styled from "styled-components";

const DefaultContentPreloader = () => {
    return (
        <Vbox gap="20px">
            <div><Skeleton width="800px" height="30px" /></div>
            <div><Skeleton width="1000px" height="30px" /></div>
            <div><Skeleton width="800px" height="30px" /></div>
            <div><Skeleton width="200px" height="100px" /></div>
            <div><Skeleton width="500px" height="30px" /></div>
            <div><Skeleton width="350px" height="30px" /></div>
        </Vbox>
    );
};

const Container = styled(Vbox)`
    padding: 30px;

    @media(max-width: ${props => props.theme.mediaSizes.medium}) {
        padding: 20px;
    }

    @media(max-width: ${props => props.theme.mediaSizes.small}) {
        padding: 10px;
    }
`

const BreadcrumbWrapper = styled.div`
    display: block;
    margin-bottom: 15px;

    // @media(max-width: ${props => props.theme.mediaSizes.small}) {
    //     display: none; // На мобильных устройствах не показываем бредкрамбы
    // }
`

const PageTitle = styled.span`
    font-size: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media(max-width: ${props => props.theme.mediaSizes.small}) {
        font-size: 22px;
    }
`

// Загрузка работает только один раз
const PageWrapper = ({children, breadcrumbs, title, loading: outerLoading, ContentPreloader = DefaultContentPreloader}) => {
    const [loading, setLoading] = useState(outerLoading);
    const [mountTime] = useState(Date.now());
    
    useEffect(() => { // Загрузка работает только на выключение
        if(outerLoading === false) { // Если загрузка меньтше 800 мс, то увеличиваем ее до 800мс. Если больше, то не увеличиваем
            const timeLeft = Date.now() - mountTime;
            setTimeout(() => {
                setLoading(false);
            }, timeLeft > 500 ? 0 : 500 - timeLeft);
        }
    }, [outerLoading]);

    return <Container gap="10px">
        {breadcrumbs?.length && <BreadcrumbWrapper>
            <Breadcrumb items={[...(breadcrumbs ? breadcrumbs : []), { title: <a>{title}</a> }]} />
        </BreadcrumbWrapper>}
        {loading ? <div><Skeleton height="40px" width="200px" /></div> : <PageTitle>{title}</PageTitle>}
        {loading ? <div style={{width: "100%", overflow: "hidden"}}><ContentPreloader /></div> : children}
    </Container>
}

export default PageWrapper;