import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import {useAuth} from "@shared/AuthContext";
import {useModal} from "@shared/ModalContext";
import Vbox from "@shared/ui/layouts/Vbox";
import Text from "@shared/ui/Text";
import makeRequest from "@shared/api/makeRequest";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import { useMemo } from "react";
import ReceiptsUploader from "@features/ReceiptUploader";
import OpenDisputeModal from "@features/OpenDisputeModal";
import ElementCard from "@shared/ui/ElementCard";


const MerchantActions = ({orderData, onSuccess}) => {
    const {user} = useAuth();
    const {openModal} = useModal();

    const menuItems = useMemo(getMenuItems, [orderData, user]);

    if(menuItems?.length < 1) return;

    return <ElementCard>
        <Vbox gap="10px">
            <Text weight="bold">Действия</Text>
            {menuItems.map(({label, onClick}) => <SimpleButton key={label} onClick={onClick}>{label}</SimpleButton>)}
            {orderData.status?.name === "dispute" && <ReceiptsUploader onSuccess={onSuccess} transaction_id={orderData.id} />}
        </Vbox>
    </ElementCard>

    function getMenuItems() {
        const items = {
            dispute: {
                any: [],
                video_confirmation_approved: [
                    RefuteDispute()
                ]
            },
            successful: {
                any: [
                    OpenDispute()
                ]
            },
            active: {
                any: [],
            },
            canceled: {
                any: [
                    OpenDispute()
                ]
            },
            validation: {
                any: []
            }
        }

        const statusItems = items[orderData.status?.name];

        if(!statusItems) return [];

        const subreasonItems = statusItems[orderData.subreason?.code] || [];
        
        return [...subreasonItems, ...statusItems['any']];

        function RefuteDispute() {
            return getButton('Оспорить диспут', async () => {
                await handleAsyncNotification(async () => {
                    await makeRequest(`/api/v1/orders/dispute/merchant/video/refute?transaction_id=${orderData.id}`, { method: "GET" });
                    onSuccess();
                }, {
                    successText: "Подтверждено поступление",
                    errorText: "Ошибка подтверждения поступления",
                    loadingText: "Подтверждение"
                })
            })
        }

        function OpenDispute() {
            return getButton('Открыть диспут', () => {openModal(<OpenDisputeModal onSuccess={onSuccess} transaction={orderData}/>, 'Открытие диспута')}) 
        }

        function getButton(label, onClick) {
            return {
                label,
                onClick,
            }
        }
    };
}

export default MerchantActions;