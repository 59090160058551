import PageWrapper from "@shared/ui/layouts/PageWrapper";
import Table from "@shared/ui/Table"
import { useNavigate } from "react-router-dom";
import makeRequest from "@shared/api/makeRequest";
import { useEffect } from "react";


const columns = [
    { title: "Название магазина", dataIndex: "name" },
    { title: "Статус", dataIndex: "status"},
    { title: "Валюта", dataIndex: "currency" },
    { title: "% мерчанта", dataIndex: "trader_percent" },
    { title: "% агента", dataIndex: "agent_percent" },
];

const items = [
    { name: "Хаб 1", status: "Активен", currency: "RUB", type: "Payin", trader_percent: "5%", agent_percent: "2%", active_requisites: "5" },
    { name: "Хаб 2", status: "Не активен", currency: "USD", type: "Payout", trader_percent: "4%", agent_percent: "1%", active_requisites: "3" },
    { name: "Хаб 3", status: "Активен", currency: "EUR", type: "Payin", trader_percent: "6%", agent_percent: "2%", active_requisites: "4" },
    { name: "Хаб 4", status: "Не активен", currency: "GBP", type: "Payout", trader_percent: "5%", agent_percent: "3%", active_requisites: "2" },
    { name: "Хаб 5", status: "Активен", currency: "RUB", type: "Payin", trader_percent: "5%", agent_percent: "2%", active_requisites: "6" },
    { name: "Хаб 6", status: "Не активен", currency: "USD", type: "Payout", trader_percent: "4%", agent_percent: "1%", active_requisites: "3" },
    { name: "Хаб 7", status: "Активен", currency: "EUR", type: "Payin", trader_percent: "6%", agent_percent: "2%", active_requisites: "7" },
    { name: "Хаб 8", status: "Не активен", currency: "GBP", type: "Payout", trader_percent: "5%", agent_percent: "3%", active_requisites: "1" },
    { name: "Хаб 9", status: "Активен", currency: "RUB", type: "Payin", trader_percent: "5%", agent_percent: "2%", active_requisites: "8" },
    { name: "Хаб 10", status: "Не активен", currency: "USD", type: "Payout", trader_percent: "4%", agent_percent: "1%", active_requisites: "4" },
    { name: "Хаб 11", status: "Активен", currency: "EUR", type: "Payin", trader_percent: "6%", agent_percent: "2%", active_requisites: "9" },
    { name: "Хаб 12", status: "Не активен", currency: "GBP", type: "Payout", trader_percent: "5%", agent_percent: "3%", active_requisites: "2" },
    { name: "Хаб 13", status: "Активен", currency: "RUB", type: "Payin", trader_percent: "5%", agent_percent: "2%", active_requisites: "5" },
    { name: "Хаб 14", status: "Не активен", currency: "USD", type: "Payout", trader_percent: "4%", agent_percent: "1%", active_requisites: "6" },
    { name: "Хаб 15", status: "Активен", currency: "EUR", type: "Payin", trader_percent: "6%", agent_percent: "2%", active_requisites: "7" },
    { name: "Хаб 16", status: "Не активен", currency: "GBP", type: "Payout", trader_percent: "5%", agent_percent: "3%", active_requisites: "3" },
    { name: "Хаб 17", status: "Активен", currency: "RUB", type: "Payin", trader_percent: "5%", agent_percent: "2%", active_requisites: "4" },
    { name: "Хаб 18", status: "Не активен", currency: "USD", type: "Payout", trader_percent: "4%", agent_percent: "1%", active_requisites: "5" },
    { name: "Хаб 19", status: "Активен", currency: "EUR", type: "Payin", trader_percent: "6%", agent_percent: "2%", active_requisites: "6" },
    { name: "Хаб 20", status: "Не активен", currency: "GBP", type: "Payout", trader_percent: "5%", agent_percent: "3%", active_requisites: "2" }
];


const Merchant = () => {
    const navigate = useNavigate();

    const breadcrumbs = [
        { title: <a onClick={() => {navigate("/statistics")}}>Статистика</a> }
    ];
    
    return <PageWrapper breadcrumbs={breadcrumbs} title="Статистика по магазинам">
        <Table dataSource={items} columns={columns} />
    </PageWrapper>
}

export default Merchant;