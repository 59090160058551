import styled from "styled-components";


const GlassCard = styled.div`
  backdrop-filter: blur(10px); /* Эффект размытости, создающий стеклянный вид */
  border-radius: 10px; /* Закругленные углы */
  background: ${(props) => props.theme.background};
  flex-direction: column;
  display: flex;
  border: solid 1px ${(props) => props.theme.border};
  gap: 20px;
  padding: 20px;
  height: ${props => props.height || "100%"};
  width: ${props => props.width || "auto"};
  overflow: hidden;

  @media(max-width: ${props => props.theme.mediaSizes.small}) {
    padding: 15px;
  }
`;


export default GlassCard;