import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import {ThreeColumns, TwoColumns} from "@shared/ui/containers/ListContainers";
import styled from "styled-components";
import useAddNavigate from "@shared/hooks/useAddNavigate";


export const SettingCard = styled(ElementCard)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    color: ${props => props.theme.color};

    &:hover {
        opacity: 0.5;
    }
`

const TradingSettings = () => {
    const addNavigate = useAddNavigate();

    return <GlassCard height="fit-content">
        <Text weight="bold">Торговые настройки</Text>
        <TwoColumns>
            <SettingCard onClick={() => {addNavigate("/tradings/banks")}}>Банки</SettingCard>
            <SettingCard onClick={() => {addNavigate("/tradings/currencies")}}>Валюты</SettingCard>
            <SettingCard onClick={() => {addNavigate("/tradings/payments")}}>Методы</SettingCard>
            <SettingCard onClick={() => {addNavigate("/tradings/trading_methods")}}>Способы торговли</SettingCard>
        </TwoColumns>
    </GlassCard>
}

export default TradingSettings;