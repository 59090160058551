import React, { useState, useEffect, Suspense } from 'react';
import Loading from '@shared/ui/Loading';
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Routes, Navigate, useRoutes } from 'react-router-dom';
import GlobalStyle from '@src/assets/styles/globalStyles';
import '@src/assets/styles/fonts.css';
import '@src/assets/styles/App.css';
import { fadeIn } from '@src/assets/animations';
import { AuthProvider } from '@shared/AuthContext';
import Icon from "@shared/ui/icons/Icon";
import InvitationPage from '../pages/InvitationPage';
import ProtectedRoute from '../widgets/ProtectedRoute';
import Sidebar from '@src/widgets/Sidebar';
import Header from '@src/widgets/Header';
import AppRoutes from '@src/app/routes/appRoutes';
import { RedocStandalone } from 'redoc';
import { ToastContainer } from 'react-toastify';
import MobileHeader from '../widgets/MobileHeader';
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider } from '@shared/ModalContext';
import LoginPage from "../pages/LoginPage";
import ApiProvider from '@shared/api/ApiProvider';
import TwoFactorAuthPage from '../pages/TwoFactorAuthPage';
import { MoonOutlined } from '@ant-design/icons';
import TraderPayoutsConfirmator from '../features/TraderPayoutsConfirmator';
import Redirect from '@pages/redirect';


const AppContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  font-family: Manrope;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 7;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.color};
  animation: ${fadeIn} 1s ease-in-out;
  transition: 1s width;
  padding-top: 50px;

  @media(max-width: ${props => props.theme.mediaSizes.small}) {
    padding-top: 0;
    padding-bottom: 60px;
  }
`;

const mediaSizes = {
  xlarge: "1400px",
  large: "1200px",
  medium: "1000px",
  small: "800px"
}

const gradients = {
  coal: "linear-gradient(90deg, #41295a, #2F0743)",
  shaLaLa: "linear-gradient(90deg, #D66D75, #E29587)",
  purplepine: "linear-gradient(90deg, #20002c, #cbb4d4)",
  pacificDream: "linear-gradient(90deg, #34e89e, #0f3443)",
  venice: "linear-gradient(90deg, #6190E8, #A7BFE8)",
}

const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  native: "white",
  secondary: "#F8F8F8",
  border: "rgb(224,224,224)",
  hover: "rgb(232,232,232)",
  skeleton: "rgb(208,208,208)",
  gradient: "linear-gradient(90deg, #FDC830, #F37335)",
  phub_gradient: gradients.shaLaLa,
  rhub_gradient: gradients.pacificDream,
  logo_src: "/icons/лого.svg",
  label: "rgb(128,128,128)",
  gradients,
  mediaSizes,
};

const darkTheme = {
  background: "#1e1f1c",
  color: "white",
  secondary: "#28282B",
  native: "#1B1B1B",
  border: "#444444",
  hover: "#343434",
  phub_gradient: gradients.coal,
  rhub_gradient: gradients.coal,
  skeleton: "#4d4d4d",
  gradient: "linear-gradient(90deg, #AA076B, #61045F)",
  logo_src: "/icons/лого.svg",
  label: "rgb(128,128,128)",
  gradients,
  mediaSizes,
};

const HeaderWrapper = styled.div`
  position: fixed; /* Фиксирует хедер сверху */
  top: 0; /* Указывает, что хедер будет на самом верху */
  left: 0;
  display: auto;
  width: 100%;
  z-index: 1000; /* Обеспечивает отображение хедера поверх других элементов */

  @media(max-width: ${props => props.theme.mediaSizes.small}) {
    display: none;
  }
`

const MobileHeaderWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  opacity: 0; /* Начальное значение прозрачности */
  transform: translateY(100%); /* Начальное смещение элемента за пределы экрана */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Плавное изменение свойств */

  @media (max-width: ${(props) => props.theme.mediaSizes.small}) {
    opacity: 1; /* Элемент становится полностью видимым */
    transform: translateY(0); /* Элемент перемещается в свое исходное положение */
  }
`

const ThemeTogglerWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  padding: 10px;
  border: solid 1px ${props => props.theme.border};
  background: ${props => props.theme.secondary};
  border-radius: 50%;

  @media(max-width: ${props => props.theme.mediaSizes.small}) {
    bottom: 80px;
  }
`

const ThemeToggler = ({onClick}) => {
  return <ThemeTogglerWrapper onClick={onClick}>
    <Icon icon={MoonOutlined} />
  </ThemeTogglerWrapper>
}


function App() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [theme, setTheme] = useState(() => localStorage.getItem("theme") || "light");
  const toggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };
  const handleClickMainContent = () => {
    if (window.innerWidth <= 1200 && isSidebarVisible) {
      setIsSidebarVisible(false);
    }
  };

  const toggleTheme = () => {
    setTheme(prev => prev === "light" ? "dark" : "light")
  }

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
          <ModalProvider>
            <ToastContainer />
            <GlobalStyle />
            <Router>
              <Routes>
                <Route path="/public/api/v1" element={<ApiDocs />} />
                <Route path="/redirect" element={<Redirect />} />
                <Route path="/login" element={<LoginPage setTheme={setTheme} />} />
                <Route path="/2fa" element={<TwoFactorAuthPage />} />
                <Route path="/invite/:invitationId" element={<InvitationPage />} />
                {/* Защищенные маршруты */}
                  <Route
                    path="/*" // Здесь идет защита всех маршрутов
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'merchant', 'trader', 'support', 'analyst']}>
                        <AppContainer>
                          <TraderPayoutsConfirmator />
                          <Sidebar isVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} closeSidebar={() => {setIsSidebarVisible(false)}} />
                          <MainContent
                            onClick={handleClickMainContent}
                          >
                            <HeaderWrapper>
                              <Header theme={theme} setTheme={setTheme} toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
                            </HeaderWrapper>
                            <ContentContainer>
                              {/* <Suspense fallback={<Loading />}> */}
                                <ApiProvider />
                                <AppRoutes />
                              {/* </Suspense> */}
                            </ContentContainer>
                            <ThemeToggler onClick={toggleTheme} /> 
                            <MobileHeaderWrapper>
                              <MobileHeader toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
                            </MobileHeaderWrapper>
                          </MainContent>
                        </AppContainer>
                      </ProtectedRoute>
                    }
                  />
              </Routes>
            </Router>
          </ModalProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}




const ApiDocs = () => {
  return (
    <div>
      <RedocStandalone  specUrl={`/combined_openapi.json`} />
    </div>
  );
};





export default App;
