import React from "react";
import Assets from "@agent/Assets";
import Statistics from "@agent/Statistics";
import Merchant from "@agent/Merchant";
import Trader from "@agent/Trader";
import Agent from "@agent/Agent";
import Settings from "@agent/Settings";


const agentRoutes = [
    { path: "/assets", element: <Assets /> },
    { path: "/statistics", element: <Statistics /> },
    { path: "/settings", element: <Settings /> },
    { path: "/traders/:trader_id", element: <Trader /> },
    { path: "/merchants/:merchant_id", element: <Merchant /> },
    { path: "/agents/:agent_id", element: <Agent /> }
]

export default agentRoutes;

// import { lazy } from "react";


// const Assets = lazy(() => import("@agent/Assets"));
// const Traders = lazy(() => import("@agent/Traders"));
// const Invite = lazy(() => import("@agent/Invite"));
// const Withdraw = lazy(() => import("@agent/Withdraw"));

// const agentRoutes = [
//     { path: "/assets", element: <Assets /> },
//     { path: "/traders", element: <Traders /> },
//     { path: "/invite", element: <Invite /> }
// ]

// export default agentRoutes;